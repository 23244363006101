import React from 'react';
import { Home, ShoppingCart, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BitcoinPaymentDemoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="bg-teal-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">How to Pay with Bitcoin</h1>
          </div>
          <div className="p-6">
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
              <p className="font-bold flex items-center">
                <AlertCircle className="mr-2" size={20} />
                Note:
              </p>
              <p>This is an informational page. No actual payment will be processed here.</p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Step-by-Step Guide</h2>
            <ol className="list-decimal list-inside space-y-4">
              <li>
                <strong>Receive Bitcoin Address and Amount:</strong>
                <p className="ml-6 mt-2">After placing your order, you'll receive a unique Bitcoin address and the exact BTC amount to send.</p>
                <div className="bg-gray-100 p-4 rounded mt-2">
                  <p><strong>Example Bitcoin Address:</strong> 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2</p>
                  <p><strong>Example BTC Amount:</strong> 0.12345678 BTC</p>
                </div>
              </li>
              <li>
                <strong>Open Your Bitcoin Wallet:</strong>
                <p className="ml-6 mt-2">Use your preferred Bitcoin wallet or exchange. If you don't have one, some popular options include Coinbase, Binance, or Electrum.</p>
              </li>
              <li>
                <strong>Initiate the Transfer:</strong>
                <p className="ml-6 mt-2">In your wallet, select 'Send' or 'Transfer' and enter the provided Bitcoin address and amount.</p>
              </li>
              <li>
                <strong>Double-Check Details:</strong>
                <p className="ml-6 mt-2">Ensure the address and amount are correct. Bitcoin transactions are irreversible!</p>
              </li>
              <li>
                <strong>Confirm and Send:</strong>
                <p className="ml-6 mt-2">Complete the transaction in your wallet. You may need to confirm via email or 2FA.</p>
              </li>
              <li>
                <strong>Wait for Confirmation:</strong>
                <p className="ml-6 mt-2">Bitcoin transactions typically take 10-60 minutes to confirm. You'll receive a notification once the payment is confirmed.</p>
              </li>
            </ol>

            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Important Notes</h2>
              <ul className="list-disc list-inside space-y-2">
                <li>Always double-check the Bitcoin address before sending.</li>
                <li>Send the exact amount requested to ensure proper order processing.</li>
                <li>Bitcoin transactions are irreversible, so take extra care when sending.</li>
                <li>If you need assistance, contact our support team immediately.</li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-4 flex justify-between">
            <button
              onClick={() => navigate('/')}
              className="flex items-center text-teal-600 hover:text-teal-800 transition duration-300"
            >
              <Home className="mr-2" size={20} />
              Home
            </button>
            <button
              onClick={() => navigate('/shop')}
              className="flex items-center text-teal-600 hover:text-teal-800 transition duration-300"
            >
              <ShoppingCart className="mr-2" size={20} />
              Shop Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BitcoinPaymentDemoPage;