import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getBlogPosts, getBlogImageUrl } from '../services/api';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getBlogPosts();
        setBlogPosts(response.data.items || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Failed to load blog posts. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const cleanTag = (tag) => {
    return tag.replace(/[\[\]"\\]/g, '').trim();
  };

  const parseTags = (tags) => {
    if (typeof tags === 'string') {
      try {
        return JSON.parse(tags).map(cleanTag).filter(tag => tag.length > 0);
      } catch (e) {
        console.error('Error parsing tags:', e);
        return [];
      }
    }
    return Array.isArray(tags) ? tags.map(cleanTag).filter(tag => tag.length > 0) : [];
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-gray-800">Our Blog</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogPosts.map((post) => (
          <Link
            key={post.id}
            to={`/blog/${post.id}`}
            className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform"
          >
            {post.image_filename && (
              <img 
                src={getBlogImageUrl(post.image_filename)} 
                alt={post.title} 
                className="w-full h-48 object-cover" 
              />
            )}
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2 text-gray-800">{post.title}</h2>
              <p className="text-gray-600 mb-4">{post.excerpt}</p>
              <div className="flex justify-between items-center mb-4 text-sm text-gray-500">
                <span>By {post.author_name || 'Unknown Author'}</span>
                <span>{new Date(post.published_at).toLocaleDateString()}</span>
              </div>
              {parseTags(post.tags).length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {parseTags(post.tags).map((tag, index) => (
                    <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs">
                      {tag}
                    </span>
                  ))}
                </div>
              )}
              <span className="text-blue-500 hover:text-blue-600">Read More</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;