import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import {
  getUserProfile,
  updateUserProfile,
  getUserPreferences,
  updateUserPreferences,
  getOrderHistory,
} from '../services/api';
import { motion } from 'framer-motion';
import {
  User,
  Mail,
  Phone,
  MapPin,
  Edit2,
  Save,
  X,
  Calendar,
  Truck,
  Bell,
  Settings,
  LogOut
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Profile = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);
  const [preferences, setPreferences] = useState({
    emailNotifications: false,
    smsNotifications: false
  });
  const [orderHistory, setOrderHistory] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setIsLoading(true);
        const [profileResponse, preferencesResponse, orderHistoryResponse] = await Promise.all([
          getUserProfile(),
          getUserPreferences(),
          getOrderHistory()
        ]);

        if (profileResponse.data) {
          setProfile(profileResponse.data);
          setEditedProfile(profileResponse.data);
        }
        if (preferencesResponse.data) {
          setPreferences({
            emailNotifications: preferencesResponse.data.email_notifications,
            smsNotifications: preferencesResponse.data.sms_notifications
          });
        }
        if (orderHistoryResponse.data && Array.isArray(orderHistoryResponse.data.items)) {
          setOrderHistory(orderHistoryResponse.data.items);
        }

        if (!profileResponse.data && !preferencesResponse.data && !orderHistoryResponse.data) {
          setError('Failed to load profile data. Please try again later.');
          toast.error('Error loading profile data');
        }
      } catch (err) {
        setError('Failed to load profile data. Please try again later.');
        console.error('Error fetching user data:', err);
        toast.error('Error loading profile data');
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfileData();
  }, []);

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setEditedProfile(profile);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const response = await updateUserProfile(editedProfile);
      setProfile(response.data);
      setIsEditing(false);
      toast.success('Profile updated successfully');
    } catch (err) {
      setError('Failed to update profile. Please try again.');
      console.error('Error updating user profile:', err);
      toast.error('Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({ ...prev, [name]: value }));
  };

  const handlePreferenceChange = async (e) => {
    const { name, checked } = e.target;
    try {
      setPreferences(prev => ({ ...prev, [name]: checked }));
      
      const updatedPreferences = { 
        ...preferences, 
        [name]: checked,
        email_notifications: name === 'emailNotifications' ? checked : preferences.emailNotifications,
        sms_notifications: name === 'smsNotifications' ? checked : preferences.smsNotifications
      };
      
      const response = await updateUserPreferences(updatedPreferences);
      
      setPreferences({
        emailNotifications: response.data.email_notifications,
        smsNotifications: response.data.sms_notifications
      });
      
      toast.success('Preferences updated successfully');
    } catch (err) {
      console.error('Error updating preferences:', err);
      setPreferences(prev => ({ ...prev, [name]: !checked }));
      toast.error('Failed to update preferences');
    }
  };

  const renderProfileCard = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gradient-to-br from-teal-500 to-teal-600 text-white rounded-lg shadow-lg p-6 mb-8"
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <div className="w-20 h-20 rounded-full bg-white text-teal-500 flex items-center justify-center text-3xl font-bold mr-4">
            {profile?.name ? profile.name[0].toUpperCase() : 'U'}
          </div>
          <div>
            <h2 className="text-2xl font-bold">{profile?.name}</h2>
            <p className="text-teal-100">{profile?.email}</p>
          </div>
        </div>
        {!isEditing ? (
          <button
            onClick={handleEdit}
            className="bg-white text-teal-500 px-4 py-2 rounded-full hover:bg-teal-100 transition-colors duration-300"
          >
            <Edit2 size={18} className="inline mr-2" />
            Edit Profile
          </button>
        ) : (
          <div className="space-x-2">
            <button
              onClick={handleSave}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-colors duration-300"
            >
              <Save size={18} className="inline mr-2" />
              Save
            </button>
            <button
              onClick={handleCancel}
              className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 transition-colors duration-300"
            >
              <X size={18} className="inline mr-2" />
              Cancel
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex items-center">
          <Phone className="mr-2" size={20} />
          <p>{profile?.addresses && profile.addresses.length > 0 ? profile.addresses[0].phone : 'No phone number'}</p>
        </div>
        <div className="flex items-center">
          <MapPin className="mr-2" size={20} />
          <p>{profile?.addresses && profile.addresses.length > 0 ? 
            `${profile.addresses[0].street}, ${profile.addresses[0].city}, ${profile.addresses[0].postcode}` : 
            'No address'}</p>
        </div>
        <div className="flex items-center">
          <Calendar className="mr-2" size={20} />
          <p>Joined: {profile?.created_at ? new Date(profile.created_at).toLocaleDateString() : 'N/A'}</p>
        </div>
      </div>
    </motion.div>
  );

  const renderPreferencesCard = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2, duration: 0.5 }}
      className="bg-white rounded-lg shadow-lg p-6 mb-8"
    >
      <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
        <Settings className="mr-2 text-teal-500" size={24} />
        Preferences
      </h3>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <label htmlFor="emailNotifications" className="flex items-center text-gray-700">
            <Bell size={18} className="mr-2 text-teal-500" />
            Receive email notifications
          </label>
          <input
            type="checkbox"
            id="emailNotifications"
            name="emailNotifications"
            checked={preferences.emailNotifications}
            onChange={handlePreferenceChange}
            className="form-checkbox h-5 w-5 text-teal-600 transition duration-150 ease-in-out"
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="smsNotifications" className="flex items-center text-gray-700">
            <Phone size={18} className="mr-2 text-teal-500" />
            Receive SMS notifications
          </label>
          <input
            type="checkbox"
            id="smsNotifications"
            name="smsNotifications"
            checked={preferences.smsNotifications}
            onChange={handlePreferenceChange}
            className="form-checkbox h-5 w-5 text-teal-600 transition duration-150 ease-in-out"
          />
        </div>
      </div>
    </motion.div>
  );

  const renderOrderHistoryCard = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4, duration: 0.5 }}
      className="bg-white rounded-lg shadow-lg p-6 mb-8"
    >
      <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
        <Truck className="mr-2 text-teal-500" size={24} />
        Recent Orders
      </h3>
      {orderHistory.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orderHistory.slice(0, 5).map((order) => (
                <tr key={order.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{order.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(order.created_at).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">£{order.total_amount.toFixed(2)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      order.status === 'completed' ? 'bg-green-100 text-green-800' :
                      order.status === 'processing' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {order.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-600">No orders found.</p>
      )}
      <div className="mt-4">
        <Link to="/profile/orders" className="text-teal-600 hover:text-teal-700 transition-colors duration-300">
          View all orders
        </Link>
      </div>
    </motion.div>
  );

  const renderQuickLinksCard = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.6, duration: 0.5 }}
      className="bg-white rounded-lg shadow-lg p-6 mb-8"
    >
      <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
        <Settings className="mr-2 text-teal-500" size={24} />
        Quick Links
      </h3>
      <div className="grid grid-cols-2 gap-4">
        <Link to="/profile/addresses" className="flex items-center text-teal-600 hover:text-teal-700 transition-colors duration-300">
          <MapPin size={18} className="mr-2" />
          Manage Addresses
        </Link>
        <Link to="/change-password" className="flex items-center text-teal-600 hover:text-teal-700 transition-colors duration-300">
          <User size={18} className="mr-2" />
          Change Password
        </Link>
        <button className="flex items-center text-red-600 hover:text-red-700 transition-colors duration-300">
          <LogOut size={18} className="mr-2" />
          Deactivate Account
        </button>
      </div>
    </motion.div>
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-teal-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 mt-8">
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()} 
          className="mt-4 bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">My Profile</h1>
      {renderProfileCard()}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {renderPreferencesCard()}
        {renderQuickLinksCard()}
      </div>
      {renderOrderHistoryCard()}
    </div>
  );
};

export default Profile;