import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProduct, createReview, updateReview, canReviewProduct } from '../services/api';
import { Star } from 'lucide-react';
import { toast } from 'react-toastify';

const ReviewProduct = () => {
  const { orderId, productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [canReview, setCanReview] = useState(null);
  const [existingReview, setExistingReview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProductAndCheckReviewability = async () => {
      try {
        const [productResponse, canReviewResponse] = await Promise.all([
          getProduct(productId),
          canReviewProduct(orderId, productId)
        ]);
        setProduct(productResponse.data);
        setCanReview(canReviewResponse.can_review);
        if (canReviewResponse.has_existing_review) {
          setExistingReview(canReviewResponse.existing_review);
          setRating(canReviewResponse.existing_review.rating);
          setComment(canReviewResponse.existing_review.comment);
        }
      } catch (error) {
        console.error('Error fetching product or checking reviewability:', error);
        toast.error('Error loading product information');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProductAndCheckReviewability();
  }, [orderId, productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      toast.error('Please select a rating');
      return;
    }
    try {
      const reviewData = {
        rating,
        comment,
        product_id: parseInt(productId),
        order_id: parseInt(orderId)
      };
      let response;
      if (existingReview) {
        response = await updateReview(existingReview.id, reviewData);
        toast.success('Review updated successfully');
      } else {
        response = await createReview(orderId, productId, reviewData);
        toast.success('Review submitted successfully');
      }
      navigate('/profile/orders');
    } catch (error) {
      console.error('Error submitting review:', error);
      toast.error('Error submitting review');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Error loading product information.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        {existingReview ? 'Update Review' : 'Review Product'}
      </h1>
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4">{product.name}</h2>
        {canReview ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Rating</label>
              <div className="flex space-x-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => setRating(star)}
                    className={`focus:outline-none ${
                      star <= rating ? 'text-yellow-400' : 'text-gray-300'
                    }`}
                  >
                    <Star fill={star <= rating ? 'currentColor' : 'none'} size={24} />
                  </button>
                ))}
              </div>
            </div>
            <div>
              <label htmlFor="comment" className="block text-sm font-medium text-gray-700 mb-2">
                Comment
              </label>
              <textarea
                id="comment"
                rows="4"
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-teal-500"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-600 transition-colors duration-300"
            >
              {existingReview ? 'Update Review' : 'Submit Review'}
            </button>
          </form>
        ) : (
          <p className="text-red-500">You are not eligible to review this product at this time.</p>
        )}
      </div>
    </div>
  );
};

export default ReviewProduct;