import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, List, ChevronLeft, ChevronRight, Filter } from 'lucide-react';
import ProductCard from '../components/ProductCard';
import FilterSidebar from '../components/FilterSidebar';
import { getProducts, getCategories, getBrands, handleApiError } from '../services/api';

const ShopPage = () => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 150]);
  const [selectedRatings, setSelectedRatings] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [sortBy, setSortBy] = useState('popularity');
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  const productsPerPage = 15; // 5 columns * 3 rows
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [categoriesResponse, brandsResponse] = await Promise.all([getCategories(), getBrands()]);
        setCategories(categoriesResponse.data);
        setBrands(brandsResponse.data.items);
      } catch (error) {
        handleApiError(error);
      }
    };

    fetchInitialData();
  }, []);

  const parseURLParams = useCallback((search) => {
    const params = new URLSearchParams(search);
    return {
      categoryIds: params.get('categories')?.split(',').map(Number).filter(Boolean) || [],
      subcategoryIds: params.get('subcategories')?.split(',').map(Number).filter(Boolean) || [],
      brandIds: params.get('brands')?.split(',').map(Number).filter(Boolean) || [],
      ratings: params.get('ratings')?.split(',').map(Number).filter(Boolean) || [],
      minPrice: Number(params.get('minPrice')) || 0,
      maxPrice: Number(params.get('maxPrice')) || 150,
      sortBy: params.get('sortBy') || 'popularity',
      page: Number(params.get('page')) || 1,
    };
  }, []);

  const updateURL = useCallback((filters) => {
    const params = new URLSearchParams();
    if (filters.categories.length) params.set('categories', filters.categories.join(','));
    if (filters.subcategories.length) params.set('subcategories', filters.subcategories.join(','));
    if (filters.brands.length) params.set('brands', filters.brands.join(','));
    if (filters.ratings.length) params.set('ratings', filters.ratings.join(','));
    if (filters.priceRange[0] > 0) params.set('minPrice', filters.priceRange[0]);
    if (filters.priceRange[1] < 150) params.set('maxPrice', filters.priceRange[1]);
    params.set('sortBy', filters.sortBy);
    params.set('page', filters.page);

    navigate(`/shop?${params.toString()}`, { replace: true });
  }, [navigate]);

  const fetchProducts = useCallback(async (filters) => {
    setIsLoading(true);
    try {
      const response = await getProducts({
        page: filters.page,
        limit: productsPerPage,
        sort: filters.sortBy,
        min_price: filters.priceRange[0],
        max_price: filters.priceRange[1],
        categories: filters.categories,
        subcategories: filters.subcategories,
        brands: filters.brands,
        ratings: filters.ratings,
      });
      setProducts(response.data.items);
      setTotalProducts(response.data.total);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const filters = parseURLParams(location.search);
    setSelectedCategories(filters.categoryIds);
    setSelectedSubcategories(filters.subcategoryIds);
    setSelectedBrands(filters.brandIds);
    setSelectedRatings(filters.ratings);
    setPriceRange([filters.minPrice, filters.maxPrice]);
    setSortBy(filters.sortBy);
    setCurrentPage(filters.page);

    fetchProducts({
      categories: filters.categoryIds,
      subcategories: filters.subcategoryIds,
      brands: filters.brandIds,
      ratings: filters.ratings,
      priceRange: [filters.minPrice, filters.maxPrice],
      sortBy: filters.sortBy,
      page: filters.page,
    });
  }, [location.search, parseURLParams, fetchProducts]);

  const handleFilterChange = useCallback((filterType, value) => {
    const newFilters = {
      categories: selectedCategories,
      subcategories: selectedSubcategories,
      brands: selectedBrands,
      ratings: selectedRatings,
      priceRange: priceRange,
      sortBy: sortBy,
      page: 1,
    };

    switch (filterType) {
      case 'brands':
      case 'categories':
      case 'subcategories':
      case 'ratings':
        newFilters[filterType] = value;
        break;
      case 'priceRange':
        newFilters.priceRange = value;
        break;
      default:
        break;
    }

    updateURL(newFilters);
  }, [selectedCategories, selectedSubcategories, selectedBrands, selectedRatings, priceRange, sortBy, updateURL]);

  const handleClearAllFilters = useCallback(() => {
    setSelectedCategories([]);
    setSelectedSubcategories([]);
    setSelectedBrands([]);
    setSelectedRatings([]);
    setPriceRange([0, 150]);
    setSortBy('popularity');
    setCurrentPage(1);
    navigate('/shop', { replace: true });
  }, [navigate]);

  const handleSortChange = useCallback((newSortBy) => {
    updateURL({
      categories: selectedCategories,
      subcategories: selectedSubcategories,
      brands: selectedBrands,
      ratings: selectedRatings,
      priceRange: priceRange,
      sortBy: newSortBy,
      page: 1,
    });
  }, [selectedCategories, selectedSubcategories, selectedBrands, selectedRatings, priceRange, updateURL]);

  const paginate = useCallback((pageNumber) => {
    updateURL({
      categories: selectedCategories,
      subcategories: selectedSubcategories,
      brands: selectedBrands,
      ratings: selectedRatings,
      priceRange: priceRange,
      sortBy: sortBy,
      page: pageNumber,
    });
  }, [selectedCategories, selectedSubcategories, selectedBrands, selectedRatings, priceRange, sortBy, updateURL]);

  const toggleMobileFilters = useCallback(() => {
    setIsMobileFiltersOpen(prev => !prev);
  }, []);

  const memoizedFilterSidebar = useMemo(() => (
    <FilterSidebar
      brands={brands}
      categories={categories}
      selectedBrands={selectedBrands}
      selectedCategories={selectedCategories}
      selectedSubcategories={selectedSubcategories}
      priceRange={priceRange}
      selectedRatings={selectedRatings}
      onFilterChange={handleFilterChange}
      onClearAllFilters={handleClearAllFilters}
      isMobileOpen={isMobileFiltersOpen}
      toggleMobileFilters={toggleMobileFilters}
    />
  ), [brands, categories, selectedBrands, selectedCategories, selectedSubcategories, priceRange, selectedRatings, handleFilterChange, handleClearAllFilters, isMobileFiltersOpen, toggleMobileFilters]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {memoizedFilterSidebar}
  
        <div className="w-full lg:flex-1">
          <div className="flex flex-wrap items-center justify-between mb-6">
            <div className="flex items-center mb-4 lg:mb-0">
              <button
                className="lg:hidden mr-4 p-2 bg-gray-100 rounded-md"
                onClick={toggleMobileFilters}
              >
                <Filter size={20} />
              </button>
              <h1 className="text-2xl font-bold">{totalProducts} Products found</h1>
            </div>
            <div className="flex items-center space-x-4">
              <select 
                className="border p-2 rounded-md shadow-sm"
                value={sortBy}
                onChange={(e) => handleSortChange(e.target.value)}
              >
                <option value="popularity">Sort by popularity</option>
                <option value="price-low-high">Price: Low to High</option>
                <option value="price-high-low">Price: High to Low</option>
                <option value="rating">Average Rating</option>
              </select>
              <button 
                className={`p-2 rounded-md ${viewMode === 'grid' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setViewMode('grid')}
              >
                <Grid size={20} />
              </button>
              <button 
                className={`p-2 rounded-md ${viewMode === 'list' ? 'bg-teal-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setViewMode('list')}
              >
                <List size={20} />
              </button>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <p className="text-xl">Loading products...</p>
            </div>
          ) : (
            <div className={`
              grid gap-4
              ${viewMode === 'grid' ? 'grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5' : 'grid-cols-1'}
            `}>
              {products.slice(0, productsPerPage).map(product => (
                <ProductCard key={product.id} product={product} viewMode={viewMode} />
              ))}
            </div>
          )}
          <div className="mt-8 flex justify-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mr-2 px-4 py-2 border rounded-md disabled:opacity-50"
            >
              <ChevronLeft size={20} />
            </button>
            {Array.from({ length: Math.min(5, Math.ceil(totalProducts / productsPerPage)) }, (_, i) => (
              <button
                key={i}
                onClick={() => paginate(i + 1)}
                className={`mx-1 px-4 py-2 border rounded-md ${currentPage === i + 1 ? 'bg-teal-500 text-white' : ''}`}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalProducts / productsPerPage)}
              className="ml-2 px-4 py-2 border rounded-md disabled:opacity-50"
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShopPage);