import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Youtube, Instagram } from 'lucide-react';
import { getBlogPosts, getFAQs, getCategories, handleApiError } from '../services/api';
const Footer = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const fetchData = useCallback(async () => {
    try {
      const [postsResponse, faqsResponse, categoriesResponse] = await Promise.all([
        getBlogPosts({ limit: 5 }),
        getFAQs(),
        getCategories()
      ]);

      setRecentPosts(postsResponse.data.items);
      setFaqs(faqsResponse.data);
      setCategories(categoriesResponse.data);
    } catch (error) {
      setError('Failed to load footer data');
      handleApiError(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const quickLinks = useMemo(() => {
    return categories.flatMap(category => 
      category.subcategories.slice(0, 2).map(subcategory => ({
        id: subcategory.id,
        name: subcategory.name
      }))
    ).slice(0, 7);
  }, [categories]);

  if (error) {
    return (
      <footer className="bg-gray-100 py-8">
        <div className="container mx-auto px-4">
          <p>Error: {error}</p>
        </div>
      </footer>
    );
  }

  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {/* Contact Us section */}
          <div>
            <h3 className="font-semibold mb-4 text-teal-400">Contact Us</h3>
            <p className="text-sm mb-4">info@ukpharma.com</p>
            <div className="mb-4">
              <h4 className="font-semibold mb-2 text-teal-400">Reviews</h4>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <p className="text-sm">REVIEWS.io</p>
            </div>
          </div>

          {/* Recent Posts section */}
          <div>
            <h3 className="font-semibold mb-4 text-teal-400">Recent Posts</h3>
            <ul className="text-sm space-y-2">
              {recentPosts.map((post) => (
                <li key={post.id}>
                  <Link to={`/blog/${post.id}`} className="hover:text-teal-300 transition-colors duration-200">
                    {post.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Links section */}
          <div>
            <h3 className="font-semibold mb-4 text-teal-400">Quick Links</h3>
            <ul className="text-sm space-y-2">
              {quickLinks.map((link) => (
              <li key={link.id}>
                <Link
                  to={`/shop?subcategories=${link.id}`}
                  className="hover:text-teal-300 transition-colors duration-200"
                >
                  {link.name}
                </Link>
              </li>
            ))}
            </ul>
          </div>

          {/* More Info section */}
          <div>
            <h3 className="font-semibold mb-4 text-teal-400">More Info</h3>
            <ul className="text-sm space-y-2">
              <li><Link to="/terms" className="hover:text-teal-300 transition-colors duration-200">Terms & Conditions</Link></li>
              <li><Link to="/privacy" className="hover:text-teal-300 transition-colors duration-200">Privacy Policy</Link></li>
              <li><Link to="/delivery" className="hover:text-teal-300 transition-colors duration-200">Delivery Information</Link></li>
              <li><Link to="/contact" className="hover:text-teal-300 transition-colors duration-200">Contact us</Link></li>
              <li><Link to="/blog" className="hover:text-teal-300 transition-colors duration-200">BLOG</Link></li>
              <li><Link to="/bitcoin-payment-demo" className="hover:text-teal-300 transition-colors duration-200">Bitcoin Payment Guide</Link></li>
              <li><Link to="/bank-transfer-demo" className="hover:text-teal-300 transition-colors duration-200">Bank Transfer Guide</Link></li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div>
            <h3 className="font-semibold mb-4 text-teal-400">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-400 transition-colors duration-200">
                <Facebook size={20} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-400 transition-colors duration-200">
                <Twitter size={20} />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-400 transition-colors duration-200">
                <Youtube size={20} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-teal-400 transition-colors duration-200">
                <Instagram size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright and Chat Button */}
        <div className="container mx-auto px-4">
          <div className="mt-8 pt-8 border-t border-gray-700 flex justify-between items-center">
              <p className="text-sm text-gray-400">&copy; {new Date().getFullYear()} UK Pharma. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;