import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditionsPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">Terms and Conditions</h1>
        
        <div className="bg-white rounded-lg shadow-md p-8">
          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">1. These terms and conditions</h2>
            <p className="text-gray-600 mb-4">
              1.1 These terms and conditions, together with our Website, Privacy Policy, set out the terms of use that you agree when you access our online store. By accessing and continuing to use our website, you agree to these terms and conditions. If you do not agree to these terms and conditions, please do not access or use our website.
            </p>
            <p className="text-gray-600 mb-4">
              1.2 You are required to confirm that you have read this document and that you understand and accept the terms before you place an online order at UKPharma.com.
            </p>
            <p className="text-gray-600 mb-4">
              1.3 Please note that these terms and conditions are subject to change. Therefore it is recommended that you review this document prior to every order, to ensure that you are aware of our current terms.
            </p>
            <p className="text-gray-600">
              1.4 These terms and conditions set out affect your rights under your statutory rights under the Sale of Goods Act 1979.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Order and dispatch</h2>
            <p className="text-gray-600 mb-4">
              2.1 All orders are subject to the prior confirmation as office to purchase medicine. Goods are subject to confirmation and acceptance and may be offered on the basis "while stocks last" or similar notice. Orders for goods that are out of stock or no longer available may be cancelled.
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>The order cannot be cancelled due to a stock issue unless informed by UKPharma.</li>
              <li>Once an order is fully processed orders on this store are final and UKPharma has initiated the delivery, the customer has accepted and will order items as dispatched.</li>
              <li>The customer is responsible for providing the correct shipping address at checkout.</li>
              <li>The address that we receive by the customer via the order received through our online shop.</li>
            </ul>
            <p className="text-gray-600">
              2.2 We will endeavour to dispatch your order within the estimated time frames listed on our website. However, in the event of any delays due to unforeseen events, you will be notified in advance. We cannot guarantee next day or same day delivery for any orders placed.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">3. Prices and payments</h2>
            <p className="text-gray-600 mb-4">
              3.1 If your have any queries or concerns regarding medication, drug interactions and conditions, or if you think that your order was wrongly rejected, you can contact UKPharma.com's customer service department for help.
            </p>
            <p className="text-gray-600 mb-4">
              3.2 Prices</p>
            <p className="text-gray-600 mb-4">
              Unless indicated, our listed VIP rights apply to orders shipped to customers located in the United States. We reserve our rights to amend our prices, offers, products and these conditions at any time without prior notice. All prices shown include VAT (Value Added Tax).
            </p>
            <p className="text-gray-600">
              3.3 We confirm that we will not charge your credit/debit card until we have dispatched your order.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">4. Delivery</h2>
            <p className="text-gray-600 mb-4">
              We use Royal Mail or other courier of your choice when signing this order confirmation process. You can check the tracking using our 'Check my order' page for updates on your order status.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Return delivery</h2>
            <p className="text-gray-600 mb-4">
              5.1 Please contact our customer care team to arrange for all returns. We accept returns of any item purchased within 14 days of delivery.
            </p>
            <p className="text-gray-600 mb-4">
              5.2 For all prescription medicines, products and medical devices, return of items will be accepted in unopened and unused condition.
            </p>
            <p className="text-gray-600 mb-4">
              5.3 For a full refund (excluding any shipping/delivery costs) of your order we estimate: although return shipping only be covered instructing us to accept returns prior to you sending them back.
            </p>
            <p className="text-gray-600 mb-4">
              5.4 Prescription services fees will be refunded for healthcare consultations in selected packages.
            </p>
            <p className="text-gray-600">
              5.5 Medicines that are returned to us will be destroyed according to government regulations. Unfortunately, unused medicines that are returned to the pharmacy, will be disposed of, regardless of their condition or expiry date. This is in accordance with UK law for any storage or resale of returned medication.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Refunds</h2>
            <p className="text-gray-600 mb-4">
              6.1 We accept returns for refund or a swap, dependent on the status based on the time of order placement.
            </p>
            <p className="text-gray-600 mb-4">
              6.2 Refunds excluding any charges for postage & packaging and consultation fees paid before.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">7. Feedback</h2>
            <p className="text-gray-600">
              If you wish to report a problem or submit any feedback to you UKPharma.com service, or have any other enquiries about your account & order history, please contact our customer service team.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Additional terms of use</h2>
            <p className="text-gray-600 mb-4">
              8.1 The information provided on this website is intended for users who have of legal age to purchase medical items and information cannot be used for self-diagnosis purposes.
            </p>
            <p className="text-gray-600 mb-4">
              8.2 It is your responsibility that our website is only used for its intended purpose and use.
            </p>
            <p className="text-gray-600 mb-4">
              8.3 Any information obtained from this website will be at customer's own risk. The customer should always consult a doctor prior to taking any medication.
            </p>
            <p className="text-gray-600 mb-4">
              8.4 UKPharma.com reserves the right to change this online store terms of use at any time. Please check this page to find policy updates.
            </p>
            <p className="text-gray-600 mb-4">
              8.5 As a customer, you acknowledge you agree that you are responsible for keeping all login credentials, including the email address and password, confidential and you agree not to allow any other person to access or use your account.
            </p>
            <p className="text-gray-600 mb-4">
              8.6 We reserve the right to refuse service, terminate accounts, remove or edit content in our sole discretion.
            </p>
            <p className="text-gray-600 mb-4">
              8.7 Orders made using invalid or fraudulent details for either billing and or delivery address will be automatically refused for customer security.
            </p>
            <p className="text-gray-600 mb-4">
              8.8 UKPharma.com reserves the right to change product prices without any prior notification.
            </p>
            <p className="text-gray-600 mb-4">
              8.9 UKPharma.com reserves the right to refuse or cancel any order at its sole discretion.
            </p>
            <p className="text-gray-600 mb-4">
              8.10 UKPharma.com shall not be responsible for price changes by our manufacturers and distributors.
            </p>
            <p className="text-gray-600">
              8.11 By using our website, you agree to abide by these terms and conditions stated here in our Privacy Policy Statement. UKPharma.com reserves the right to change these terms and conditions at any time without prior notice to you.
            </p>
          </section>
        </div>

        <div className="mt-8 text-center">
          <Link to="/contact" className="text-teal-600 hover:underline">
            Contact us if you have any questions about our Terms and Conditions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;