import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ShopPage from './pages/ShopPage';
import Contact from './pages/Contact';
import AuthPage from './pages/AuthPage';
import ProductDisplayPage from './pages/ProductDisplay';
import BlogPage from './pages/Blog';
import ContactPage from './pages/Contact';
import BlogPostPage from './components/BlogPostPage';
import ScrollToTop from './components/ScrollToTop';
import ResetPasswordPage from './pages/ResetPasswordPage';
import { AuthProvider } from './context/AuthContext';
import Profile from './pages/Profile'
import AdminDashboard from './pages/AdminDashboard';
import CartPage from './pages/CartPage';
import ProtectedRoute from './components/ProtectedRoute';
import { CartProvider } from './context/CartContext';
import { WishlistProvider } from './context/WishlistContext';
import CheckoutPage from './pages/CheckOutPage';
import ViewAllOrders from './components/ViewAllOrders';
import ManageAddresses from './components/ManageAddresses';
import BlogManagement from './components/AdminComponents/BlogManagement';
import BitcoinPaymentPage from './pages/BitcoinPayment';
import BankTransferPaymentPage from './pages/BankTransferPayment';
import DeliveryInformationPage from './pages/DeliveryInfo';
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import ReviewProduct from './components/ReviewProduct';
import BitcoinPaymentDemoPage from './pages/BitcoinPaymentDemo';
import BankTransferDemoPage from './pages/BankTransferDemo';
function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <WishlistProvider>
          <Router>
            <ScrollToTop />
            <div className="flex flex-col min-h-screen">
              <Header />
              <main className="flex-grow">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/shop" element={<ShopPage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/auth" element={<AuthPage />} />
                  <Route path="/product/:id" element={<ProductDisplayPage />} />
                  <Route path="/blog" element={<BlogPage />}/>
                  <Route path="/profile" element={<Profile />}/>
                  <Route path="/profile/orders" element={<ViewAllOrders />} />
                  <Route path="/profile/addresses" element={<ManageAddresses />} />
                  <Route path="/contact" element={<ContactPage />}/>
                  <Route path="/blog/:id" element={<BlogPostPage />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route path="/delivery-info" element={<DeliveryInformationPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/terms" element={<TermsAndConditionsPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/bitcoin-payment" element={<BitcoinPaymentPage />} />
                  <Route path="/bitcoin-payment-demo" element={<BitcoinPaymentDemoPage />} />
                  <Route path="/bank-transfer-demo" element={<BankTransferDemoPage />} /> 
                  <Route path="/review/:orderId/:productId" element={<ReviewProduct />} />
                  <Route path="/bank-transfer-payment" element={<BankTransferPaymentPage />} />
                  <Route path="/admin/*" element={<ProtectedRoute adminOnly><AdminDashboard /></ProtectedRoute>} />
                  <Route path="/admin/blog/*" element={<ProtectedRoute adminOnly><BlogManagement /></ProtectedRoute>} />
                </Routes>
              </main>
              <Footer />
            </div>
          </Router>
          <ToastContainer />
        </WishlistProvider>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;