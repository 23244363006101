import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getUserProfile, login as apiLogin, logout as apiLogout } from '../services/api';

const AuthContext = createContext({
  user: null,
  isAuthenticated: false,
  login: async () => {},
  logout: async () => {},
  loading: true,
  forceUpdate: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateKey, setUpdateKey] = useState(0);

  const loadUser = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await getUserProfile();
        setUser(response.data);
      } catch (error) {
        console.error('Failed to load user:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
        }
        setUser(null);
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser, updateKey]);

  const login = async (credentials) => {
    try {
      const response = await apiLogin(credentials);
      localStorage.setItem('token', response.data.access_token);
      setUser(response.data.user);
      setUpdateKey(prev => prev + 1);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error.response?.data || error.message);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await apiLogout();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      setUser(null);
      setUpdateKey(prev => prev + 1);
    }
  };

  const forceUpdate = () => {
    setUpdateKey(prev => prev + 1);
  };

  const value = {
    user,
    isAuthenticated: !!user,
    login,
    logout,
    loading,
    reloadUser: loadUser,
    forceUpdate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);