import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useAuth } from '../context/AuthContext';
import { Trash2, Plus, Minus } from 'lucide-react';
import { getStaticImageUrl } from '../services/api';
import { motion, AnimatePresence } from 'framer-motion';
import CheckoutPrompt from '../components/CheckoutPrompt';

const CartPage = () => {
  const { cart, removeFromCart, updateQuantity, getCartTotal } = useCart();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [showCheckoutPrompt, setShowCheckoutPrompt] = useState(false);

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity > 0) {
      updateQuantity(itemId, newQuantity);
    }
  };

  const formatPrice = (price) => {
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return !isNaN(numPrice) ? numPrice.toFixed(2) : 'N/A';
  };

  const handleCheckout = () => {
    if (isAuthenticated) {
      navigate('/checkout');
    } else {
      setShowCheckoutPrompt(true);
    }
  };

  const closeCheckoutPrompt = () => {
    setShowCheckoutPrompt(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-gray-800">Your Shopping Cart</h1>
      {cart.length === 0 ? (
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center py-8"
        >
          <p className="text-xl mb-4 text-gray-600">Your cart is empty</p>
          <Link to="/shop" className="bg-teal-500 text-white py-2 px-4 rounded-md hover:bg-teal-600 transition-colors duration-200 shadow-md hover:shadow-lg">
            Continue Shopping
          </Link>
        </motion.div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-8">
          <motion.div 
            className="lg:w-2/3"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            {cart.map((item) => (
              <motion.div 
                key={item.id} 
                className="flex items-center border-b py-4 hover:bg-gray-50 rounded-lg px-4 transition-colors duration-200"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <img
                  src={getStaticImageUrl(item.product_image_url) || '/placeholder-image.jpg'}
                  alt={item.product_name}
                  className="w-24 h-24 object-cover rounded-md mr-4 border border-gray-200 shadow-sm"
                />
                <div className="flex-grow">
                  <h3 className="font-semibold text-lg text-gray-800">{item.product_name}</h3>
                  <p className="text-gray-600 text-sm">{item.product_brand}</p>
                  <p className="font-bold text-teal-600">£{formatPrice(item.product_price)}</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                    className="p-1 rounded-full hover:bg-gray-200 text-gray-600 hover:text-teal-600 transition-colors duration-200"
                  >
                    <Minus size={16} />
                  </button>
                  <span className="mx-2 font-semibold">{item.quantity}</span>
                  <button
                    onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                    className="p-1 rounded-full hover:bg-gray-200 text-gray-600 hover:text-teal-600 transition-colors duration-200"
                  >
                    <Plus size={16} />
                  </button>
                </div>
                <button
                  onClick={() => removeFromCart(item.id)}
                  className="ml-4 text-red-500 hover:text-red-700 transition-colors duration-200"
                >
                  <Trash2 size={20} />
                </button>
              </motion.div>
            ))}
          </motion.div>
          <motion.div 
            className="lg:w-1/3"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <div className="bg-gray-100 p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Order Summary</h2>
              <div className="flex justify-between mb-2 text-gray-600">
                <span>Subtotal</span>
                <span>£{formatPrice(getCartTotal())}</span>
              </div>
              <div className="flex justify-between mb-2 text-gray-600">
                <span>Shipping</span>
                <span>Calculated at checkout</span>
              </div>
              <div className="border-t pt-2 mt-2">
                <div className="flex justify-between font-bold text-lg text-gray-800">
                  <span>Total</span>
                  <span>£{formatPrice(getCartTotal())}</span>
                </div>
              </div>
              <button
                onClick={handleCheckout}
                className="block w-full text-center bg-teal-500 text-white py-3 px-4 rounded-md hover:bg-teal-600 transition-colors duration-200 mt-4 shadow-md hover:shadow-lg font-semibold"
              >
                Proceed to Checkout
              </button>
              <Link
                to="/shop"
                className="block w-full text-center bg-gray-200 text-gray-800 py-3 px-4 rounded-md hover:bg-gray-300 transition-colors duration-200 mt-2 shadow-md hover:shadow-lg font-semibold"
              >
                Continue Shopping
              </Link>
            </div>
          </motion.div>
        </div>
      )}
      <AnimatePresence>
        {showCheckoutPrompt && (
          <CheckoutPrompt onClose={closeCheckoutPrompt} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default CartPage;