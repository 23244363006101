import React from 'react';
import { Home, ShoppingCart, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const BankTransferDemoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="bg-blue-600 px-6 py-4">
            <h1 className="text-2xl font-bold text-white">How to Pay with Bank Transfer</h1>
          </div>
          <div className="p-6">
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
              <p className="font-bold flex items-center">
                <AlertCircle className="mr-2" size={20} />
                Note:
              </p>
              <p>This is an informational page. No actual payment will be processed here.</p>
            </div>

            <h2 className="text-xl font-semibold mb-4">Step-by-Step Guide</h2>
            <ol className="list-decimal list-inside space-y-4">
              <li>
                <strong>Receive Bank Details:</strong>
                <p className="ml-6 mt-2">After placing your order, you'll receive our bank account details and a unique reference number.</p>
                <div className="bg-gray-100 p-4 rounded mt-2">
                  <p><strong>Example Bank Details:</strong></p>
                  <p>Account Name: Example Company Ltd</p>
                  <p>Account Number: 12345678</p>
                  <p>Sort Code: 12-34-56</p>
                  <p>Reference: ORD-123456</p>
                </div>
              </li>
              <li>
                <strong>Log into Your Online Banking:</strong>
                <p className="ml-6 mt-2">Access your bank's website or mobile app and log into your account.</p>
              </li>
              <li>
                <strong>Set Up a New Payee:</strong>
                <p className="ml-6 mt-2">If this is your first time paying us, you'll need to add our account as a new payee.</p>
              </li>
              <li>
                <strong>Enter Transfer Details:</strong>
                <p className="ml-6 mt-2">Input the account number, sort code, and the exact amount to be paid.</p>
              </li>
              <li>
                <strong>Add the Reference:</strong>
                <p className="ml-6 mt-2">In the reference field, enter the unique reference number provided. This is crucial for identifying your payment.</p>
              </li>
              <li>
                <strong>Double-Check All Details:</strong>
                <p className="ml-6 mt-2">Ensure all information is correct before proceeding.</p>
              </li>
              <li>
                <strong>Confirm and Send:</strong>
                <p className="ml-6 mt-2">Complete the transaction. You may need to use a card reader or enter a code sent to your phone.</p>
              </li>
              <li>
                <strong>Save the Confirmation:</strong>
                <p className="ml-6 mt-2">Keep a record of the transaction confirmation for your reference.</p>
              </li>
            </ol>

            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Important Notes</h2>
              <ul className="list-disc list-inside space-y-2">
                <li>Bank transfers may take 1-3 business days to process.</li>
                <li>Always use the provided reference number to ensure your payment is correctly allocated.</li>
                <li>If you're paying from outside the UK, additional fees may apply. Check with your bank.</li>
                <li>For any issues or questions, contact our support team with your order number.</li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-50 px-6 py-4 flex justify-between">
            <button
              onClick={() => navigate('/')}
              className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300"
            >
              <Home className="mr-2" size={20} />
              Home
            </button>
            <button
              onClick={() => navigate('/shop')}
              className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300"
            >
              <ShoppingCart className="mr-2" size={20} />
              Shop Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransferDemoPage;