import React, { useState, useEffect } from 'react';
import { getOrderHistory, getOrderByIdUser } from '../services/api';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Calendar, 
  Truck, 
  DollarSign, 
  Package, 
  Check, 
  Clock, 
  X, 
  ChevronLeft, 
  ChevronRight, 
  User, 
  MapPin, 
  CreditCard, 
  Bitcoin 
} from 'lucide-react';
import { Link } from 'react-router-dom';
const ViewAllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const itemsPerPage = 9;

  useEffect(() => {
    fetchOrders();
  }, [currentPage]);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const response = await getOrderHistory(currentPage, itemsPerPage);
      console.log('API Response:', response);
      setOrders(response.items);
      setTotalPages(response.pages);
    } catch (err) {
      setError('Failed to load order history. Please try again later.');
      console.error('Error fetching order history:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewDetails = async (orderId) => {
    try {
      const orderDetails = await getOrderByIdUser(orderId);
      console.log('Order Details:', orderDetails);
      setSelectedOrder(orderDetails);
      setIsModalOpen(true);
    } catch (err) {
      console.error('Error fetching order details:', err);
      alert('Failed to load order details. Please try again.');
    }
  };

  const formatPrice = (price) => {
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return numPrice.toFixed(2);
  };

  const renderStatusBadge = (status) => {
    const statusConfig = {
      pending: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800', icon: <Clock size={14} /> },
      paid: { bgColor: 'bg-green-100', textColor: 'text-green-800', icon: <DollarSign size={14} /> },
      processing: { bgColor: 'bg-blue-100', textColor: 'text-blue-800', icon: <Package size={14} /> },
      shipped: { bgColor: 'bg-indigo-100', textColor: 'text-indigo-800', icon: <Truck size={14} /> },
      delivered: { bgColor: 'bg-green-100', textColor: 'text-green-800', icon: <Check size={14} /> },
      cancelled: { bgColor: 'bg-red-100', textColor: 'text-red-800', icon: <X size={14} /> },
    };
  
    const { bgColor, textColor, icon } = statusConfig[status] || statusConfig.pending;
  
    return (
      <span className={`px-3 py-1 rounded-full text-xs font-medium ${bgColor} ${textColor} flex items-center justify-center`}>
        <span className="mr-1">{icon}</span>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  const OrderDetailsModal = ({ order, onClose }) => {
    if (!order) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50"
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-white rounded-lg shadow-xl p-6 w-full max-w-4xl max-h-[90vh] overflow-y-auto"
        >
          <h2 className="text-3xl font-bold mb-6 text-teal-700">Order #{order.id}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Order Status</h3>
                {renderStatusBadge(order.status)}
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Order Details</h3>
                <p><Calendar className="inline mr-2" size={18} /> {new Date(order.created_at).toLocaleString()}</p>
                <p><DollarSign className="inline mr-2" size={18} /> Total: £{formatPrice(order.total_amount)}</p>
                {order.tracking_number && (
                  <p><Package className="inline mr-2" size={18} /> Tracking: {order.tracking_number}</p>
                )}
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Payment Information</h3>
                <p>
                  {order.payment.payment_method === 'bitcoin' ? (
                    <Bitcoin className="inline mr-2" size={18} />
                  ) : (
                    <CreditCard className="inline mr-2" size={18} />
                  )}
                  Method: {order.payment.payment_method}
                </p>
                <p>Status: {order.payment.status}</p>
                {order.payment.transaction_id && <p>Transaction ID: {order.payment.transaction_id}</p>}
              </div>
            </div>
            <div className="space-y-4">
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Shipping Address</h3>
                <address>
                  <MapPin className="inline mr-2" size={18} />
                  {order.shipping_address.full_name}<br />
                  {order.shipping_address.address_line1}<br />
                  {order.shipping_address.address_line2 && <>{order.shipping_address.address_line2}<br /></>}
                  {order.shipping_address.city}, {order.shipping_address.state} {order.shipping_address.postal_code}<br />
                  {order.shipping_address.country}
                </address>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="font-semibold text-lg mb-2">Billing Address</h3>
                <address>
                  <MapPin className="inline mr-2" size={18} />
                  {order.billing_address.full_name}<br />
                  {order.billing_address.address_line1}<br />
                  {order.billing_address.address_line2 && <>{order.billing_address.address_line2}<br /></>}
                  {order.billing_address.city}, {order.billing_address.state} {order.billing_address.postal_code}<br />
                  {order.billing_address.country}
                </address>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="font-semibold text-lg mb-2">Order Items</h3>
            {order.order_items && order.order_items.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {order.order_items.map((item) => (
                    <tr key={item.id}>
                      <td className="px-6 py-4 whitespace-nowrap">{item.product ? item.product.name : 'Unknown Product'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{item.quantity}</td>
                      <td className="px-6 py-4 whitespace-nowrap">£{formatPrice(item.price_at_time_of_order)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">£{formatPrice(item.price_at_time_of_order * item.quantity)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {order.status === 'delivered' && (
                          <Link
                            to={`/review/${order.id}/${item.product_id}`}
                            className="bg-teal-500 text-white px-3 py-1 rounded hover:bg-teal-600 transition-colors duration-300"
                          >
                            Review Product
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No items found for this order.</p>
            )}
          </div>
          <div className="mt-6 bg-gray-100 p-4 rounded-lg">
            <h3 className="font-semibold text-lg mb-2">Order Summary</h3>
            <p>Subtotal: £{formatPrice(order.total_amount - order.delivery_cost)}</p>
            <p>Delivery Cost: £{formatPrice(order.delivery_cost)}</p>
            {order.discount_amount > 0 && (
              <p>Discount: -£{formatPrice(order.discount_amount)}</p>
            )}
            <p className="font-bold mt-2">Total: £{formatPrice(order.total_amount)}</p>
          </div>
          <button
            onClick={onClose}
            className="mt-6 bg-teal-500 text-white px-6 py-3 rounded-lg hover:bg-teal-600 transition-colors duration-300 w-full"
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-teal-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 mt-8">
        <p>{error}</p>
        <button 
          onClick={() => fetchOrders()} 
          className="mt-4 bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-4xl font-bold text-teal-700 mb-8">Order History</h1>
      {orders.length > 0 ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {orders.map((order) => (
              <motion.div 
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
              >
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-teal-700 mb-4">Order #{order.id}</h2>
                  <div className="space-y-3">
                    <div className="flex items-center">
                      <Calendar className="text-teal-500 mr-2" size={18} />
                      <p className="text-gray-600">{new Date(order.created_at).toLocaleDateString()}</p>
                    </div>
                    <div className="flex items-center">
                      <DollarSign className="text-teal-500 mr-2" size={18} />
                      <p className="text-gray-600">Total: £{formatPrice(order.total_amount)}</p>
                    </div>
                    <div className="flex items-center">
                      <Truck className="text-teal-500 mr-2" size={18} />
                      {renderStatusBadge(order.status)}
                    </div>
                    {order.tracking_number && (
                      <div className="flex items-center">
                        <Package className="text-teal-500 mr-2" size={18} />
                        <p className="text-gray-600">Tracking: {order.tracking_number}</p>
                      </div>
                    )}
                  </div>
                  <button 
                    onClick={() => handleViewDetails(order.id)}
                    className="mt-6 bg-teal-500 text-white px-4 py-2 rounded-lg hover:bg-teal-600 transition-colors duration-300 w-full"
                  >
                    View Details
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="mt-8 flex justify-center items-center space-x-4">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500"
              >
                <ChevronLeft size={20} />
              </button>
              <span className="text-lg font-medium">{currentPage} of {totalPages}</span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-teal-500"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          )}
        </>
      ) : (
        <p className="text-gray-600 text-center text-xl">No orders found.</p>
      )}
      <AnimatePresence>
        {isModalOpen && (
          <OrderDetailsModal 
            order={selectedOrder} 
            onClose={() => {
              setIsModalOpen(false);
              setSelectedOrder(null);
            }}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ViewAllOrders;