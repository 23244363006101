import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import BlogList from './BlogList';
import BlogEditor from './BlogEditor';

const BlogManagement = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isEditing = location.pathname.includes('/edit/') || location.pathname.includes('/new');

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800">Blog Management</h1>
          {isEditing ? (
            <button 
              onClick={() => navigate('/admin/blog')} 
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
              </svg>
              Back to Blog List
            </button>
          ) : (
            <button 
              onClick={() => navigate('/admin/blog/new')} 
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
              </svg>
              Add New Post
            </button>
          )}
        </div>
        <div className="bg-white rounded-lg shadow-md">
          <Routes>
            <Route index element={<BlogList />} />
            <Route path="new" element={<BlogEditor />} />
            <Route path="edit/:id" element={<BlogEditor />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default BlogManagement;