import React from 'react';
import { Copy } from 'lucide-react';

const formatPrice = (price) => {
  return typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);
};

const BankTransferPayment = ({ paymentDetails, totalAmount }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  if (!paymentDetails) {
    return <div className="text-red-600">Error: Payment details not available</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-gray-800">Payment Details</h3>
      <p className="mb-4 text-gray-700">Please transfer <span className="font-bold">£{formatPrice(totalAmount)}</span> to the following bank account:</p>
      <div className="space-y-3 mb-6">
        {[
          { label: 'Account Name', value: paymentDetails.account_name },
          { label: 'Account Number', value: paymentDetails.account_number },
          { label: 'Sort Code', value: paymentDetails.sort_code },
          { label: 'Reference', value: paymentDetails.reference },
        ].map(({ label, value }) => (
          <div key={label} className="flex items-center justify-between">
            <span className="font-semibold text-gray-600">{label}:</span>
            <div className="flex items-center">
              <span className="bg-gray-100 py-1 px-2 rounded mr-2">{value || 'Not provided'}</span>
              <button 
                onClick={() => copyToClipboard(value)}
                className="bg-gray-200 p-1 rounded hover:bg-gray-300 transition duration-300"
                title={`Copy ${label}`}
              >
                <Copy size={16} />
              </button>
            </div>
          </div>
        ))}
      </div>
      <p className="text-sm text-gray-600 bg-yellow-100 p-3 rounded-lg">
        Please use the reference provided when making the transfer. Your order will be processed once the payment is received, which typically takes 1-3 business days.
      </p>
    </div>
  );
};

export default BankTransferPayment;