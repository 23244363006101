import React, { useState, useEffect } from 'react';
import { getAdminProducts, createAdminProduct, updateAdminProduct, deleteAdminProduct, getBrands, getCategories, searchAdminProducts } from '../../services/api';
import { Plus, Edit, Trash2, ChevronLeft, ChevronRight, Star, X, Search } from 'lucide-react';
import { getStaticImageUrl } from '../../services/api';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(9);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getAdminProducts(currentPage, itemsPerPage);
      if (response && Array.isArray(response.items)) {
        setProducts(response.items);
        setTotalPages(Math.ceil(response.total / itemsPerPage));
      } else {
        console.error('Unexpected API response format:', response);
        setError('Unexpected data format received from the server.');
        setProducts([]);
      }
    } catch (err) {
      setError('Failed to load products. Please try again.');
      console.error('Error fetching products:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateProduct = async (productData) => {
    try {
      console.log('Original productData:', productData);
  
      const formData = new FormData();
      Object.keys(productData).forEach(key => {
        if (key === 'images') {
          productData.images.forEach((image, index) => {
            console.log(`Appending image ${index}:`, image);
            formData.append('images', image);
          });
        } else if (key === 'price' || key === 'stock_quantity' || key === 'brand_id' || key === 'category_id' || key === 'subcategory_id') {
          console.log(`Appending ${key}:`, productData[key].toString());
          formData.append(key, productData[key].toString());
        } else {
          console.log(`Appending ${key}:`, productData[key]);
          formData.append(key, productData[key]);
        }
      });
  
      console.log('FormData entries:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
  
      const response = await createAdminProduct(formData);
      console.log('Response from createAdminProduct:', response);
      setProducts(prevProducts => [...prevProducts, response]);
      fetchProducts();
    } catch (err) {
      setError('Failed to create product. Please try again.');
      console.error('Error creating product:', err);
      if (err.response) {
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
        console.error('Response headers:', err.response.headers);
      }
    }
  };

  const handleUpdateProduct = async (id, productData) => {
    console.log('Updating product:', id, productData);
    try {
      const formData = new FormData();
      Object.keys(productData).forEach(key => {
        if (key === 'images') {
          productData.images.forEach((image, index) => {
            console.log(`Appending image ${index}:`, image);
            formData.append('images', image);
          });
        } else if (key === 'category' || key === 'subcategory' || key === 'brand') {
          console.log(`Appending ${key}_id:`, productData[key].id);
          formData.append(key + '_id', productData[key].id);
        } else {
          console.log(`Appending ${key}:`, productData[key]);
          formData.append(key, productData[key]);
        }
      });

      const response = await updateAdminProduct(id, formData);
      console.log('Update response:', response);
      setProducts(prevProducts => 
        prevProducts.map(product => product.id === id ? response : product)
      );
      setEditingProduct(null);
    } catch (err) {
      setError('Failed to update product. Please try again.');
      console.error('Error updating product:', err);
      if (err.response) {
        console.error('Response data:', err.response.data);
        console.error('Response status:', err.response.status);
        console.error('Response headers:', err.response.headers);
      } else if (err.request) {
        console.error('No response received:', err.request);
      } else {
        console.error('Error setting up request:', err.message);
      }
    }
  };

  const handleDeleteProduct = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteAdminProduct(id);
        setProducts(prevProducts => prevProducts.filter(product => product.id !== id));
      } catch (err) {
        setError('Failed to delete product. Please try again.');
        console.error('Error deleting product:', err);
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatPrice = (price) => {
    if (typeof price === 'number') {
      return price.toFixed(2);
    } else if (typeof price === 'string') {
      return parseFloat(price).toFixed(2);
    }
    return 'N/A';
  };

  const renderStars = (rating) => {
    const numericRating = parseFloat(rating);
    const ratingValue = isNaN(numericRating) ? 0 : numericRating;
    return (
      <div className="flex items-center">
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            size={16}
            className={i < Math.floor(ratingValue) ? "text-yellow-400 fill-current" : "text-gray-300"}
          />
        ))}
        <span className="ml-2 text-sm text-gray-600">
          ({isNaN(numericRating) ? 'N/A' : ratingValue.toFixed(1)})
        </span>
      </div>
    );
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await searchAdminProducts(searchQuery, currentPage, itemsPerPage);
      if (response && Array.isArray(response.items)) {
        setProducts(response.items);
        setTotalPages(Math.ceil(response.total / itemsPerPage));
      } else {
        console.error('Unexpected API response format:', response);
        setError('Unexpected data format received from the server.');
        setProducts([]);
      }
    } catch (err) {
      setError('Failed to search products. Please try again.');
      console.error('Error searching products:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    fetchProducts(); // Reset to initial product list
  };

  if (isLoading) return <div className="text-center py-10">Loading products...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Product Management</h1>
      <button
        onClick={() => setEditingProduct({})}
        className="mb-8 bg-teal-500 text-white px-6 py-2 rounded-full hover:bg-teal-600 transition-colors duration-300 flex items-center shadow-lg"
      >
        <Plus size={20} className="mr-2" />
        Add New Product
      </button>
      
      {editingProduct !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-w-4xl w-full max-h-screen overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">{editingProduct.id ? 'Edit Product' : 'Add New Product'}</h2>
              <button onClick={() => setEditingProduct(null)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <ProductForm
              product={editingProduct}
              onSave={(productData) => {
                if (editingProduct.id) {
                  handleUpdateProduct(editingProduct.id, productData);
                } else {
                  handleCreateProduct(productData);
                }
                setEditingProduct(null);
              }}
              onCancel={() => setEditingProduct(null)}
            />
          </div>
        </div>
      )}

      <form onSubmit={handleSearch} className="mb-6 relative">
        <div className="flex items-center">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for product name, brand or category..."
            className="flex-grow p-2 pl-10 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-teal-500 placeholder-gray-400"
          />
          <button
            type="submit"
            className="bg-teal-500 text-white p-2 rounded-r-md hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
          >
            <Search size={20} />
          </button>
        </div>
        <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        {searchQuery && (
          <button
            type="button"
            onClick={handleClearSearch}
            className="absolute right-14 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
          >
            <X size={20} />
          </button>
        )}
      </form>

      {products.length === 0 ? (
        <p className="text-center py-10 text-gray-500">No products found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map(product => {
            const imageUrl = product.image_urls && product.image_urls.length > 0
              ? getStaticImageUrl(product.image_urls[0])
              : `https://via.placeholder.com/300x300?text=${encodeURIComponent(product.name)}`;

            return (
              <div key={product.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 hover:scale-105">
                <img src={imageUrl} alt={product.name} className="w-full h-64 object-cover" />
                <div className="p-6">
                  <h3 className="font-bold text-xl mb-2 truncate">{product.name}</h3>
                  <p className="text-gray-600 mb-2">{product.brand?.name || 'Unknown Brand'}</p>
                  <p className="text-teal-600 font-bold text-2xl mb-2">£{formatPrice(product.price)}</p>
                  <p className="text-sm text-gray-700 mb-4 h-12 overflow-hidden">{product.short_description}</p>
                  {renderStars(product.rating)}
                  <p className="text-sm text-gray-600 mt-2">Reviews: {product.num_reviews}</p>
                  <p className="text-sm text-gray-600">Stock: {product.stock_quantity}</p>
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      onClick={() => setEditingProduct(product)}
                      className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
                    >
                      <Edit size={20} />
                    </button>
                    <button
                      onClick={() => handleDeleteProduct(product.id)}
                      className="text-red-500 hover:text-red-700 transition-colors duration-300"
                    >
                      <Trash2 size={20} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-12 flex justify-center items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 rounded-full bg-gray-200 disabled:opacity-50 hover:bg-gray-300 transition-colors duration-300"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-medium">{currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 rounded-full bg-gray-200 disabled:opacity-50 hover:bg-gray-300 transition-colors duration-300"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

const ProductForm = ({ product, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    ...product,
    category_id: product?.category?.id || '',
    subcategory_id: product?.subcategory?.id || '',
    brand_id: product?.brand?.id || '',
    images: []
  });
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [imagePreviews, setImagePreviews] = useState(product?.image_url || []);

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && formData.category_id) {
      const selectedCategory = categories.find(cat => cat.id === parseInt(formData.category_id));
      setSubcategories(selectedCategory?.subcategories || []);
    }
  }, [categories, formData.category_id]);

  const fetchDropdownData = async () => {
    try {
      const [brandsResponse, categoriesResponse] = await Promise.all([
        getBrands(),
        getCategories()
      ]);
      setBrands(brandsResponse.data.items || []);
      setCategories(categoriesResponse.data || []);

      if (formData.category_id) {
        const selectedCategory = categoriesResponse.data.find(cat => cat.id === parseInt(formData.category_id));
        setSubcategories(selectedCategory?.subcategories || []);
      }
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = ['price', 'stock_quantity'].includes(name) ? parseFloat(value) : value;
    
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));

    if (name === 'category_id') {
      const selectedCategory = categories.find(cat => cat.id === parseInt(value));
      setSubcategories(selectedCategory?.subcategories || []);
      setFormData(prev => ({ ...prev, subcategory_id: '' }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData(prev => ({ ...prev, images: [...prev.images, ...files] }));
    
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setImagePreviews(prev => [...prev, ...newPreviews]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Product Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          />
        </div>

        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">Price (£)</label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price || ''}
            onChange={handleChange}
            step="0.01"
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          />
        </div>

        <div>
          <label htmlFor="stock_quantity" className="block text-sm font-medium text-gray-700 mb-1">Stock Quantity</label>
          <input
            type="number"
            id="stock_quantity"
            name="stock_quantity"
            value={formData.stock_quantity || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          />
        </div>

        <div>
          <label htmlFor="brand_id" className="block text-sm font-medium text-gray-700 mb-1">Brand</label>
          <select
            id="brand_id"
            name="brand_id"
            value={formData.brand_id || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          >
            <option value="">Select a brand</option>
            {brands.map(brand => (
              <option key={brand.id} value={brand.id}>{brand.name}</option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="category_id" className="block text-sm font-medium text-gray-700 mb-1">Category</label>
          <select
            id="category_id"
            name="category_id"
            value={formData.category_id || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          >
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="subcategory_id" className="block text-sm font-medium text-gray-700 mb-1">Subcategory</label>
          <select
            id="subcategory_id"
            name="subcategory_id"
            value={formData.subcategory_id || ''}
            onChange={handleChange}
            className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
            required
          >
            <option value="">Select a subcategory</option>
            {subcategories.map(subcategory => (
              <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label htmlFor="short_description" className="block text-sm font-medium text-gray-700 mb-1">Short Description</label>
        <input
          type="text"
          id="short_description"
          name="short_description"
          value={formData.short_description || ''}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Full Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description || ''}
          onChange={handleChange}
          rows="4"
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        ></textarea>
      </div>

      <div>
        <label htmlFor="images" className="block text-sm font-medium text-gray-700 mb-1">Product Images</label>
        <input
          type="file"
          id="images"
          name="images"
          onChange={handleImageChange}
          accept="image/*"
          multiple
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
        />
        <div className="mt-2 flex flex-wrap gap-2">
          {imagePreviews.map((preview, index) => (
            <img key={index} src={preview} alt={`Product preview ${index + 1}`} className="w-24 h-24 object-cover rounded" />
          ))}
        </div>
      </div>

      <div className="flex justify-end space-x-4 mt-8">
        <button 
          type="button" 
          onClick={onCancel} 
          className="px-6 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition-colors duration-300"
        >
          Cancel
        </button>
        <button 
          type="submit" 
          className="px-6 py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition-colors duration-300"
        >
          {formData.id ? 'Update Product' : 'Add Product'}
        </button>
      </div>
    </form>
  );
};

export default ProductManagement;