import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const CheckoutPrompt = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        className="bg-white p-8 rounded-lg max-w-md w-full"
      >
        <h2 className="text-2xl font-bold mb-4">Create an account or log in</h2>
        <p className="mb-6">To complete your purchase, please create an account or log in.</p>
        <div className="flex justify-between">
          <Link
            to="/auth?mode=signup"
            className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors"
          >
            Create Account
          </Link>
          <Link
            to="/auth?mode=login"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          >
            Log In
          </Link>
        </div>
        <button
          onClick={onClose}
          className="mt-4 text-gray-600 hover:text-gray-800 transition-colors"
        >
          Continue as guest
        </button>
      </motion.div>
    </motion.div>
  );
};

export default CheckoutPrompt;