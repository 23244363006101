import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAdminBlogPosts, deleteAdminBlogPost } from '../../services/api';
import { Edit, Trash2, Eye } from 'lucide-react';

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      setIsLoading(true);
      const response = await getAdminBlogPosts();
      setBlogPosts(response.data.items);
    } catch (err) {
      setError('Failed to load blog posts. Please try again.');
      console.error('Error fetching blog posts:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePost = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deleteAdminBlogPost(id);
        setBlogPosts(blogPosts.filter(post => post.id !== id));
      } catch (err) {
        setError('Failed to delete blog post. Please try again.');
        console.error('Error deleting blog post:', err);
      }
    }
  };

  if (isLoading) return <div className="text-center py-10">Loading blog posts...</div>;
  if (error) return <div className="text-red-500 text-center py-10">{error}</div>;

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Author</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Views</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {blogPosts.map(post => (
            <tr key={post.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">{post.title}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{post.author_name || 'Anonymous'}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{new Date(post.created_at).toLocaleDateString()}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  post.status === 'published' ? 'bg-green-100 text-green-800' : 
                  post.status === 'draft' ? 'bg-yellow-100 text-yellow-800' : 
                  'bg-red-100 text-red-800'
                }`}>
                  {post.status.charAt(0).toUpperCase() + post.status.slice(1)}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-500">{post.view_count}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <Link to={`/blog/${post.id}`} className="text-indigo-600 hover:text-indigo-900 mr-3">
                  <Eye className="inline-block w-5 h-5" />
                </Link>
                <Link to={`/admin/blog/edit/${post.id}`} className="text-yellow-600 hover:text-yellow-900 mr-3">
                  <Edit className="inline-block w-5 h-5" />
                </Link>
                <button onClick={() => handleDeletePost(post.id)} className="text-red-600 hover:text-red-900">
                  <Trash2 className="inline-block w-5 h-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BlogList;