import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X, ChevronRight } from 'lucide-react';
import { getSearchSuggestions, getStaticImageUrl } from '../services/api';
import { motion, AnimatePresence } from 'framer-motion';

const SearchBar = ({ isMobile = false }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (searchQuery.length > 2) {
        try {
          const data = await getSearchSuggestions(searchQuery);
          console.log('Suggestions:', data);
          setSuggestions(data);
          setIsDropdownOpen(true);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setSuggestions([]);
          setIsDropdownOpen(false);
        }
      } else {
        setSuggestions([]);
        setIsDropdownOpen(false);
      }
    };

    const debounce = setTimeout(() => {
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
      setSearchQuery('');
      setIsDropdownOpen(false);
    }
  };

  const handleSuggestionClick = (productId) => {
    navigate(`/product/${productId}`);
    setSearchQuery('');
    setIsDropdownOpen(false);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setIsDropdownOpen(false);
  };
    return (
      <div className={`relative ${isMobile ? 'w-full' : 'w-full max-w-7xl mx-auto'}`} ref={searchRef}>
        <form onSubmit={handleSearch} className="relative w-full">
          <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={() => setIsFocused(true)}
            className={`w-full border border-gray-300 rounded-full py-3 px-5 pr-12 text-sm transition-all duration-300 ${
              isFocused
                ? 'border-teal-400 ring-2 ring-teal-100'
                : 'hover:border-teal-300'
            } ${
              isMobile ? 'bg-white text-gray-800' : 'bg-gray-50 text-gray-800'
            } placeholder-gray-400 focus:outline-none`}
          />
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
            {searchQuery && (
              <button
                type="button"
                onClick={handleClearSearch}
                className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
              >
                <X size={18} />
              </button>
            )}
            <button
              type="submit"
              className="bg-teal-500 text-white p-2 rounded-full hover:bg-teal-600 transition-colors duration-200"
            >
              <Search size={18} />
            </button>
          </div>
        </form>
        <AnimatePresence>
          {isDropdownOpen && suggestions.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className={`absolute z-50 w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-xl ${
                isMobile ? 'max-h-80 overflow-y-auto' : ''
              }`}
            >
              {suggestions.map((suggestion, index) => (
                <motion.div
                  key={suggestion.id}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                  className="group"
                >
                  <div
                    className="flex items-center p-3 hover:bg-gray-50 cursor-pointer transition-colors duration-200 relative"
                    onClick={() => handleSuggestionClick(suggestion.id)}
                  >
                    <img 
                      src={getStaticImageUrl(suggestion.image_urls)} 
                      alt={suggestion.name} 
                      className="w-12 h-12 object-cover rounded-md mr-4 border border-gray-200 group-hover:border-teal-300 transition-colors duration-200"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/placeholder-image.jpg';
                      }}
                    />
                    <div className="flex flex-col flex-grow">
                      <span className="text-sm font-medium text-gray-800 group-hover:text-teal-600 transition-colors duration-200">{suggestion.name}</span>
                      <span className="text-xs text-gray-500">{suggestion.brand_name}</span>
                    </div>
                    <ChevronRight size={18} className="text-gray-400 group-hover:text-teal-500 transition-colors duration-200" />
                  </div>
                  {index < suggestions.length - 1 && (
                    <div className="mx-3 border-b border-gray-100"></div>
                  )}
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };
  
  export default SearchBar;