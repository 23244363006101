import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicyPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        <h1 className="text-3xl font-bold text-teal-600 mb-8">Privacy Policy</h1>
        
        <div className="bg-white rounded-lg shadow-md p-8">
          <p className="text-gray-600 mb-6">
            Your privacy is critically important to us. At UK Pharma Shop, we follow a few fundamental principles when it comes to confidentiality and the privacy of our customers and website visitors:
          </p>
          <ul className="list-disc pl-6 text-gray-600 mb-6">
            <li>We don't ask you for personal information unless we truly need it to process and deliver your online order.</li>
            <li>We don't share your personal information and contact details with anyone.</li>
            <li>We don't store your personal information unless required by the delivery of repeat orders and for inquiries regarding existing orders.</li>
          </ul>
          <p className="text-gray-600 mb-6">
            Our policy which incorporates all these principles and goals. If you have any questions about our privacy policy or if you require any more information, you may contact us.
          </p>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">1. Collection of Information of Website Visitors and Registered Users</h2>
            <p className="text-gray-600 mb-4">
              Like many other website operators, UKPharma.shop collects information that is commonly made available by web browsers and servers. This non-personally identifying information includes the browser type, language preference, referring site, and the date and time of each visitor to the website, and referring site. UKPharma.shop collects this information in an attempt to better understand how its visitors use the website.
            </p>
            <p className="text-gray-600 mb-4">
              UKPharma.shop also collects potentially personally-identifying information like Internet Protocol (IP) addresses of our registered customers. Any personally-identifying information that is collected by UKPharma.shop will be used and disclosed under the same circumstances as for the usage and disclosure of personally-identifying information as described below in this "Disclosure of Information" section.
            </p>
            <p className="text-gray-600 mb-4">
              The information collected by UKPharma.shop website includes, but not limited to:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>All personal details you type in and submit to our website, such as your name, email address, contact number, etc.</li>
              <li>Information you provide when you register an account on our website. This includes data that allows us to recognize you, your preferences, and how you use the website. For more information on data collected by cookies please refer to the "Use of Cookies" section.</li>
              <li>Your Internet Protocol (IP) address is automatically collected by our website.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">2. Use of Information</h2>
            <p className="text-gray-600 mb-4">
              UKPharma.shop may utilize the information automatically collected by our website and the information that you voluntarily provide us for the following purposes:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>To respond to your requests or to process your online order.</li>
              <li>To alert the user regarding any changes in our products and services.</li>
              <li>To complete tasks arising from any contracts between you and UKPharma.shop, such as the delivery of medicines ordered online by the user.</li>
              <li>To provide information regarding our products. This may include offers and news items, promotional emails, and another similar kind of messages.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">3. Security of Information</h2>
            <p className="text-gray-600 mb-4">
              UKPharma.shop uses advanced security measures to make its website safe for visitors and customers. Our online order system makes use of the latest Secured Socket Layer (SSL) Technology and all transactions are fully encrypted and protected with advanced technical security shields. Our customers can rest assured that we safeguards in place to protect you against credit card fraud. In addition to this, UKPharma.shop stores personally identifiable information on secured computers that store your information in accordance with the latest data protection guidelines.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">4. Disclosure of Information</h2>
            <p className="text-gray-600 mb-4">
              UKPharma.shop takes the confidentiality of users' information seriously and will only share your personal information under exceptional conditions. We may share your personal information if:
            </p>
            <ul className="list-disc pl-6 text-gray-600 mb-4">
              <li>There is a legal compulsion to do so. For instance, if you provide information regarding the safety and adverse effects of a medicine, UKPharma.shop will be obliged to share this information with the manufacturer so that they can perform their reporting obligations and take necessary measures.</li>
            </ul>
            <p className="text-gray-600 mb-4">
              UKPharma.shop ensures that your information and contact details will not be traded or transferred to any third party for marketing purposes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">5. Use of Third-Party Links</h2>
            <p className="text-gray-600 mb-4">
              UKPharma.shop occasionally uses third-party links. Our website may include and offer links to third-party products and services at the company's discretion. Since these third-party websites have their own independent and distinct privacy policies, UKPharma.shop cannot be held liable for any inappropriate content or actions related to these third-party websites. However, we still seek to protect the integrity of our site and welcome any feedback about these sites.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">6. Use of Cookies</h2>
            <p className="text-gray-600 mb-4">
              Our website uses cookies. By using the UKPharma.shop website and agreeing to this privacy policy, you consent to www.UKPharma.shop.com's use of cookies in accordance with the terms of this policy.
            </p>
            <p className="text-gray-600 mb-4">
              "Cookies" are small files of data stored by a web browser, and stored by the web browser. The information contained in the file is sent back to the server each time a request is made to that web server.
            </p>
            <p className="text-gray-600 mb-4">
              Cookies are of two types – session cookies and persistent cookies. UKPharma.shop generally uses persistent cookies that remain stored on the user's computer until deleted, or until they reach a specified expiry date.
            </p>
          </section>
        </div>

        <div className="mt-8 text-center">
          <Link to="/contact" className="text-teal-600 hover:underline">
            Contact us if you have any questions about our Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;