import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { X, ShoppingBag, Trash2, Plus, Minus } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { getStaticImageUrl } from '../services/api';

const CartDropdown = ({ onClose }) => {
  const { cart, removeFromCart, getCartTotal, updateQuantity } = useCart();

  const formatPrice = (price) => {
    if (price === null || price === undefined) return 'N/A';
    const numPrice = typeof price === 'string' ? parseFloat(price) : price;
    return !isNaN(numPrice) ? `£${numPrice.toFixed(2)}` : 'N/A';
  };

  const calculateItemTotal = (item) => {
    const price = parseFloat(item.product_price);
    const quantity = parseInt(item.quantity);
    return !isNaN(price) && !isNaN(quantity) ? formatPrice(price * quantity) : 'N/A';
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    if (newQuantity > 0) {
      updateQuantity(itemId, newQuantity);
    }
  };

  const total = getCartTotal();
  const formattedTotal = formatPrice(total);

  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="absolute right-0 mt-2 w-96 bg-white rounded-lg shadow-2xl z-50 border border-gray-200"
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-gray-800 flex items-center">
            <ShoppingBag size={24} className="mr-2 text-teal-500" />
            Your Cart
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors duration-200">
            <X size={24} />
          </button>
        </div>
        <AnimatePresence>
          {cart.length === 0 ? (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center text-gray-500 py-8"
            >
              Your cart is empty
            </motion.p>
          ) : (
            <>
              <div className="max-h-80 overflow-y-auto pr-2">
                {cart.map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="flex items-center justify-between mb-4 bg-gray-50 p-3 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                  >
                    <div className="flex items-center">
                      <img 
                        src={getStaticImageUrl(item.product_image_url) || '/placeholder-image.jpg'} 
                        alt={item.product_name} 
                        className="w-16 h-16 object-cover rounded-md mr-4 border border-gray-200"
                      />
                      <div>
                        <p className="font-semibold text-gray-800 text-sm">{item.product_name}</p>
                        <p className="text-xs text-gray-600">{formatPrice(item.product_price)} each</p>
                        <div className="flex items-center mt-1">
                          <button
                            onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                            className="p-1 rounded-full hover:bg-gray-200 text-gray-600 hover:text-teal-600 transition-colors duration-200"
                          >
                            <Minus size={14} />
                          </button>
                          <span className="mx-2 text-sm">{item.quantity}</span>
                          <button
                            onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                            className="p-1 rounded-full hover:bg-gray-200 text-gray-600 hover:text-teal-600 transition-colors duration-200"
                          >
                            <Plus size={14} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="font-bold text-teal-600 text-sm">{calculateItemTotal(item)}</p>
                      <button
                        onClick={() => removeFromCart(item.id)}
                        className="text-red-500 hover:text-red-700 transition-colors duration-200 mt-2"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </motion.div>
                ))}
              </div>
              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-semibold text-gray-800">Total:</span>
                  <span className="text-xl font-bold text-teal-600">{formattedTotal}</span>
                </div>
                <Link
                  to="/cart"
                  className="block w-full text-center bg-teal-500 text-white py-3 px-4 rounded-lg hover:bg-teal-600 transition-colors duration-200 font-semibold shadow-md hover:shadow-lg"
                  onClick={onClose}
                >
                  View Cart and Checkout
                </Link>
              </div>
            </>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default CartDropdown;