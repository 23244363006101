import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CreditCard, Truck, Star, CheckCircle, Shield } from 'lucide-react';
import ProductCard from '../components/ProductCard';
import { getProducts, getCategories, getBlogPosts, getStaticImageUrl, handleApiError } from '../services/api';
const backgroundImageUrl = '/images/hero-bg.jpg';
const HomePage = () => {
  const [bestSellers, setBestSellers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const reviews = [
    { id: 1, rating: 5, text: "Incredible gains, fast results!", author: "Jake P." },
    { id: 2, rating: 4, text: "Quality products, noticed a difference in just weeks.", author: "Sarah L." },
  ];

  const faqs = [
    { question: "How do I start my cycle?", answer: "Choose your preferred steroids and add them to your cart. Follow our guides for the best results." },
    { question: "What about post-cycle therapy?", answer: "We offer a range of PCT products to ensure you maintain your gains and keep your body in balance." },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [productsResponse, categoriesResponse, blogPostsResponse] = await Promise.all([
          getProducts({
            page: 1,
            limit: 4,
            sort: 'popularity',
            min_price: 0,
            max_price: 1000,
          }),
          getCategories(),
          getBlogPosts({ limit: 3 })
        ]);

        setBestSellers(productsResponse.data.items || []);
        setCategories(categoriesResponse.data || []);
        setBlogPosts(blogPostsResponse.data.items || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load page data. Please try again later.');
        setLoading(false);
        handleApiError(error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-16">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-top text-white p-16 rounded-2xl shadow-lg mb-16"
        style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-2xl"></div>
        <div className="relative z-10 text-center">
          <h1 className="text-6xl font-extrabold tracking-tight leading-tight mb-6">
            Top Quality <span className="text-teal-400">Steroids</span> Delivered Worldwide
          </h1>
          <p className="text-2xl font-light mb-8">
            Trusted & Tested Products | Reship Guarantee | Secure Payment
          </p>
          <div className="flex justify-center space-x-4">
            <Link
              to="/shop"
              className="bg-teal-400 text-gray-900 py-3 px-8 rounded-full font-bold text-xl shadow-lg hover:bg-teal-300 transition duration-300 transform hover:-translate-y-1"
            >
              Shop Now
            </Link>
            <Link
              to="/contact"
              className="bg-white text-teal-400 py-3 px-8 rounded-full font-bold text-xl shadow-lg hover:bg-gray-100 transition duration-300 transform hover:-translate-y-1"
            >
              Contact Us
            </Link>
          </div>
          <div className="flex justify-center mt-12 space-x-6">
            <div className="flex items-center">
              <Shield size={32} className="text-teal-400 mr-2" />
              <span className="text-lg font-medium">100% Trusted</span>
            </div>
            <div className="flex items-center">
              <Truck size={32} className="text-teal-400 mr-2" />
              <span className="text-lg font-medium">Worldwide Shipping</span>
            </div>
            <div className="flex items-center">
              <CheckCircle size={32} className="text-teal-400 mr-2" />
              <span className="text-lg font-medium">Rated 5 Stars</span>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Categories */}
      <section className="mb-16">
        <h2 className="text-4xl font-extrabold mb-12 text-center">Explore Our Categories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {categories.length > 0 ? (
            categories.map((category) => {
              const categoryImageUrl = getStaticImageUrl(category.image_url);
              return (
                <Link key={category.id} to={`/shop?categories=${category.id}`} className="relative group transform hover:scale-105 transition-transform">
                  <div className="overflow-hidden rounded-xl shadow-lg">
                    <img src={categoryImageUrl} alt={category.name} className="w-full h-60 object-cover group-hover:scale-110 transition-transform duration-700" />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent rounded-xl opacity-0 group-hover:opacity-80 transition-opacity duration-300 flex items-center justify-center">
                      <h3 className="text-3xl font-bold text-white drop-shadow-lg">{category.name}</h3>
                    </div>
                  </div>
                </Link>
              );
            })
          ) : (
            <p>No categories available</p>
          )}
        </div>
      </section>

      {/* Best Sellers */}
      <section className="mb-16">
        <h2 className="text-4xl font-extrabold mb-12 text-center">Top-Selling Steroids</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-12">
          {bestSellers.length > 0 ? (
            bestSellers.map(product => (
              <ProductCard key={product.id} product={product} viewMode="grid" />
            ))
          ) : (
            <p>No best sellers available</p>
          )}
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="mb-16 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 p-16 rounded-3xl text-white shadow-lg">
        <h2 className="text-4xl font-extrabold mb-12 text-center">Why Choose Our Steroids?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="flex items-center transform hover:scale-105 transition-transform">
            <div className="relative flex-shrink-0">
              <CreditCard size={50} className="text-teal-400 mr-6" />
              <div className="absolute inset-0 bg-teal-400 rounded-full blur-lg opacity-20 animate-pulse"></div>
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-2">Certified Quality</h3>
              <p className="text-lg">Our products are lab-tested for purity and potency.</p>
            </div>
          </div>
          <div className="flex items-center transform hover:scale-105 transition-transform">
            <div className="relative flex-shrink-0">
              <Truck size={50} className="text-teal-400 mr-6" />
              <div className="absolute inset-0 bg-teal-400 rounded-full blur-lg opacity-20 animate-pulse"></div>
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-2">Fast, Discreet Shipping</h3>
              <p className="text-lg">Your order is delivered quickly and in plain packaging.</p>
            </div>
          </div>
          <div className="flex items-center transform hover:scale-105 transition-transform">
            <div className="relative flex-shrink-0">
              <Star size={50} className="text-teal-400 mr-6" />
              <div className="absolute inset-0 bg-teal-400 rounded-full blur-lg opacity-20 animate-pulse"></div>
            </div>
            <div>
              <h3 className="text-2xl font-bold mb-2">Unmatched Results</h3>
              <p className="text-lg">Experience the power of our top-rated steroids.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Recent Blog Posts */}
      <section className="mb-16">
        <h2 className="text-4xl font-extrabold mb-12 text-center">Latest from Our Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {blogPosts.map(post => (
            <Link key={post.id} to={`/blog/${post.id}`} className="bg-white rounded-xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform">
              <img src={post.image} alt={post.title} className="w-full h-60 object-cover" />
              <div className="p-6">
                <h3 className="text-2xl font-bold mb-4">{post.title}</h3>
                <p className="text-lg text-gray-700 mb-4">{post.excerpt}</p>
                <p className="text-lg text-teal-600 font-semibold">Read more →</p>
              </div>
            </Link>
          ))}
        </div>
      </section>

      {/* Customer Reviews */}
      <section className="mb-16">
        <h2 className="text-4xl font-extrabold mb-12 text-center">What Our Customers Say</h2>
        <div className="bg-gradient-to-r from-teal-500 to-blue-500 p-12 rounded-3xl shadow-lg text-white">
          <div className="overflow-x-auto">
            <div className="flex space-x-8">
              {reviews.map(review => (
                <div key={review.id} className="flex-shrink-0 w-96 bg-white bg-opacity-10 p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-4">
                    {[...Array(review.rating)].map((_, i) => (
                      <Star key={i} size={24} className="text-yellow-400 fill-current" />
                    ))}
                  </div>
                  <p className="text-xl italic mb-4">"{review.text}"</p>
                  <p className="font-bold text-lg">- {review.author}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="mb-16">
        <h2 className="text-4xl font-extrabold mb-12 text-center">FAQs</h2>
        <div className="bg-gray-100 p-12 rounded-3xl shadow-lg">
          {faqs.map((faq, index) => (
            <div key={index} className="mb-8 last:mb-0">
              <h3 className="text-2xl font-bold mb-4">{faq.question}</h3>
              <p className="text-lg text-gray-700">{faq.answer}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HomePage;
