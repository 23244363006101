import React, { useState } from 'react';
import { Star, Eye, Heart, ShoppingCart } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import QuickViewModal from './QuickViewModal';
import { handleApiError, getStaticImageUrl } from '../services/api';
import { useCart } from '../context/CartContext';
import { useWishlist } from '../context/WishlistContext';
import { useAuth } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';

const ProductCard = ({ product }) => {
  const [showQuickView, setShowQuickView] = useState(false);
  const { addToCart } = useCart();
  const { addToWishlist, removeFromWishlist, isInWishlist } = useWishlist();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleAddToCart = async (e) => {
    e.preventDefault();
    try {
      await addToCart(product, 1);
      toast.success('Product added to cart!');
    } catch (error) {
      toast.error('Failed to add product to cart');
      handleApiError(error);
    }
  };

  const handleToggleWishlist = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/auth');
      return;
    }
    try {
      if (isInWishlist(product.id)) {
        await removeFromWishlist(product.id);
        toast.success('Removed from wishlist');
      } else {
        await addToWishlist(product.id);
        toast.success('Added to wishlist');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const renderStars = () => (
    <div className="flex items-center">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          size={12}
          className={i < Math.floor(product.rating) ? "text-yellow-400 fill-current" : "text-gray-300"}
        />
      ))}
      <span className="ml-1 text-xs text-gray-500">({product.num_reviews})</span>
    </div>
  );

  const formatPrice = (price) => {
    return typeof price === 'number' ? price.toFixed(2) : parseFloat(price).toFixed(2);
  };

  const imageUrl = product && product.image_urls 
    ? getStaticImageUrl(product.image_urls) 
    : `https://via.placeholder.com/300x300?text=${encodeURIComponent(product?.name || 'Product')}`;

  return (
    <motion.div 
      className="relative bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Link to={`/product/${product.id}`} className="block relative aspect-square overflow-hidden">
        <img 
          src={imageUrl} 
          alt={product.name} 
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        {product.stock_quantity > 0 && (
          <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-0.5 rounded-full">
            In Stock
          </span>
        )}
        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-5 transition-opacity duration-300" />
      </Link>

      <div className="p-3 sm:p-4 flex flex-col h-[120px] sm:h-[140px]">
        <Link to={`/product/${product.id}`} className="block flex-grow">
          <h3 className="text-sm font-medium text-gray-800 hover:text-teal-600 transition-colors duration-200 line-clamp-2">
            {product.name}
          </h3>
        </Link>
        <p className="text-xs text-gray-500 mt-1">{product.brand?.name || 'Unknown Brand'}</p>
        <div className="mt-auto">
          <div className="mb-1">{renderStars()}</div>
          <p className="font-semibold text-sm sm:text-base text-teal-600">£{formatPrice(product.price)}</p>
        </div>
      </div>

      <div className="absolute top-2 right-2 flex flex-col space-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 sm:block hidden">
        <motion.button
          onClick={(e) => {
            e.preventDefault();
            setShowQuickView(true);
          }}
          className="p-1.5 bg-white rounded-full shadow-sm hover:bg-gray-100 transition-colors duration-200 mb-2"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Eye size={14} className="text-gray-600" />
        </motion.button>
        <motion.button
          onClick={handleToggleWishlist}
          className="p-1.5 bg-white rounded-full shadow-sm hover:bg-gray-100 transition-colors duration-200"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Heart size={14} className={isInWishlist(product.id) ? "text-red-500 fill-current" : "text-gray-600"} />
        </motion.button>
      </div>

      <div className="p-3 sm:p-4 bg-gray-50 sm:bg-transparent sm:absolute sm:bottom-0 sm:left-0 sm:right-0 sm:bg-gradient-to-t sm:from-white sm:via-white sm:to-transparent sm:opacity-0 sm:group-hover:opacity-100 sm:transition-all sm:duration-300 sm:transform sm:translate-y-full sm:group-hover:translate-y-0">
        <motion.button
          onClick={handleAddToCart}
          className="w-full bg-teal-500 text-white py-2 sm:py-1.5 px-3 rounded-full flex items-center justify-center hover:bg-teal-600 transition-colors duration-200 text-sm sm:text-xs"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
        >
          <ShoppingCart size={16} className="mr-2 sm:mr-1.5" />
          Add to Cart
        </motion.button>
      </div>

      <AnimatePresence>
        {showQuickView && (
          <QuickViewModal 
            product={product} 
            onClose={() => setShowQuickView(false)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ProductCard;