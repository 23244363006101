import React, { useState, useEffect } from 'react';
import { getOrdersAdmin, getOrderById, updateOrder, deleteOrder } from '../../services/api';
import { Edit, Trash2, ChevronLeft, ChevronRight, RefreshCw, X, CreditCard, Truck, Bitcoin, Check, Clock, Package, DollarSign } from 'lucide-react';
import { toast } from 'react-toastify';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editingOrder, setEditingOrder] = useState(null);

  useEffect(() => {
    fetchOrders();
  }, [currentPage]);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await getOrdersAdmin({ page: currentPage, limit: itemsPerPage });
      
      if (response.data && Array.isArray(response.data.items)) {
        setOrders(response.data.items);
        setTotalPages(response.data.pages);
      } else {
        setError('Unexpected data format received from the server.');
        setOrders([]);
      }
    } catch (err) {
      setError('Failed to load orders. Please try again.');
      console.error('Error fetching orders:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateOrder = async (id, orderData) => {
    try {
      const response = await updateOrder(id, orderData);
      setOrders(prevOrders => 
        prevOrders.map(order => order.id === id ? response.data : order)
      );
      toast.success('Order updated successfully');
      setEditingOrder(null);
    } catch (err) {
      toast.error('Failed to update order. Please try again.');
    }
  };

  const handleDeleteOrder = async (id) => {
    if (window.confirm('Are you sure you want to delete this order?')) {
      try {
        await deleteOrder(id);
        setOrders(prevOrders => prevOrders.filter(order => order.id !== id));
        toast.success('Order deleted successfully');
      } catch (err) {
        toast.error('Failed to delete order. Please try again.');
      }
    }
  };

  const handleEditClick = async (id) => {
    try {
      const response = await getOrderById(id);
      setEditingOrder(response.data);
    } catch (err) {
      toast.error('Failed to fetch order details. Please try again.');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderStatusBadge = (status) => {
    const statusConfig = {
      pending: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800', icon: <Clock size={14} /> },
      paid: { bgColor: 'bg-green-100', textColor: 'text-green-800', icon: <DollarSign size={14} /> },
      processing: { bgColor: 'bg-blue-100', textColor: 'text-blue-800', icon: <Package size={14} /> },
      shipped: { bgColor: 'bg-indigo-100', textColor: 'text-indigo-800', icon: <Truck size={14} /> },
      delivered: { bgColor: 'bg-green-100', textColor: 'text-green-800', icon: <Check size={14} /> },
      cancelled: { bgColor: 'bg-red-100', textColor: 'text-red-800', icon: <X size={14} /> },
    };
  
    const { bgColor, textColor, icon } = statusConfig[status] || statusConfig.pending;
  
    return (
      <span className={`px-3 py-1 rounded-full text-xs font-medium ${bgColor} ${textColor} flex items-center justify-center`}>
        <span className="mr-1">{icon}</span>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    );
  };

  const renderPaymentMethod = (method) => {
    const methodConfig = {
      bitcoin: { icon: <Bitcoin size={16} />, text: 'Bitcoin' },
      bank_transfer: { icon: <CreditCard size={16} />, text: 'Bank Transfer' },
    };

    const { icon, text } = methodConfig[method] || {};

    return (
      <span className="flex items-center space-x-1">
        {icon}
        <span>{text}</span>
      </span>
    );
  };

  const renderDeliveryMethod = (method) => {
    return (
      <span className="flex items-center space-x-1">
        <Truck size={16} />
        <span>{method === 'standard' ? 'Standard' : 'Express'}</span>
      </span>
    );
  };

  const EditModal = ({ order, onClose, onSave }) => {
    const [editedOrder, setEditedOrder] = useState({ ...order });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setEditedOrder(prev => ({ ...prev, [name]: value }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(editedOrder.id, editedOrder);
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
        <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
          <div className="flex justify-between items-center p-6 border-b">
            <h2 className="text-xl font-bold">Edit Order #{order.id}</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <form onSubmit={handleSubmit} className="p-6">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                Status
              </label>
              <select
                id="status"
                name="status"
                value={editedOrder.status}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>
            {editedOrder.status === 'shipped' && (
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tracking_number">
                  Tracking Number
                </label>
                <input
                  type="text"
                  id="tracking_number"
                  name="tracking_number"
                  value={editedOrder.tracking_number || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total_amount">
                Total Amount
              </label>
              <input
                type="number"
                id="total_amount"
                name="total_amount"
                value={editedOrder.total_amount}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  if (isLoading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );

  if (error) return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p className="text-red-500 mb-4 text-xl">{error}</p>
      <button 
        onClick={fetchOrders} 
        className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300 flex items-center"
      >
        <RefreshCw className="mr-2" size={18} />
        Retry
      </button>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-gray-800">Order Management</h1>
      
      {orders.length === 0 ? (
        <p className="text-center py-10 text-gray-500 text-xl">No orders found.</p>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delivery</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tracking</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {orders.map(order => (
                <tr key={order.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#{order.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.user?.full_name || 'N/A'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(order.created_at).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">£{parseFloat(order.total_amount).toFixed(2)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {renderStatusBadge(order.status)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {renderPaymentMethod(order.payment?.payment_method)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {renderDeliveryMethod(order.delivery_method)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {order.tracking_number || 'N/A'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleEditClick(order.id)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      <Edit size={18} />
                    </button>
                    <button
                      onClick={() => handleDeleteOrder(order.id)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <Trash2 size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {totalPages > 1 && (
        <div className="mt-8 flex justify-center items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-medium">{currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border border-gray-300 rounded-md disabled:opacity-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500">
            <ChevronRight size={20} />
          </button>
        </div>
      )}

      {editingOrder && (
        <EditModal
          order={editingOrder}
          onClose={() => setEditingOrder(null)}
          onSave={handleUpdateOrder}
        />
      )}
    </div>
  );
};

export default OrderManagement;