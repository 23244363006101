import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createAdminBlogPost, updateAdminBlogPost, getAdminBlogPostById, getBlogImageUrl } from '../../services/api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DOMPurify from 'dompurify';
import { FaSave, FaEye, FaImage, FaTimes } from 'react-icons/fa';
import './BlogEditor.css';

const BlogEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState({
    title: '',
    excerpt: '',
    content: '',
    image_filename: '',
    tags: [],
    status: 'draft',
    seo_title: '',
    seo_description: '',
    seo_keywords: '',
    author_name: ''
  });
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (id) {
      fetchBlogPost(id);
    }
  }, [id]);

  const fetchBlogPost = async (postId) => {
    setIsLoading(true);
    try {
      const response = await getAdminBlogPostById(postId);
      setPost(response.data);
    } catch (error) {
      console.error('Error fetching blog post:', error);
      setError('Failed to load blog post. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setPost(prev => ({ ...prev, content: data }));
  };

  const handleTagChange = (e) => {
    const tags = e.target.value.split(',').map(tag => tag.trim());
    setPost(prev => ({ ...prev, tags }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      setPost(prev => ({ ...prev, imagePreview: imageUrl }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const formData = new FormData();
      Object.keys(post).forEach(key => {
        if (key === 'tags') {
          formData.append(key, JSON.stringify(post[key]));
        } else if (key !== 'imagePreview') {
          formData.append(key, post[key]);
        }
      });

      if (imageFile) {
        formData.append('image', imageFile);
      }

      console.log('Form data before submission:', Object.fromEntries(formData));

      let response;
      if (id) {
        response = await updateAdminBlogPost(id, formData);
      } else {
        response = await createAdminBlogPost(formData);
      }

      console.log('Response from server:', response);

      navigate('/admin/blog');
    } catch (error) {
      console.error('Error saving blog post:', error);
      setError('Failed to save blog post. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    const sanitizedContent = DOMPurify.sanitize(post.content, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
    });

    return (
      <div 
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      />
    );
  };

  if (isLoading) return <div className="flex items-center justify-center h-screen"><div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div></div>;
  if (error) return <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <form onSubmit={handleSubmit} className="space-y-8 bg-white shadow-lg rounded-lg p-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-900">{id ? 'Edit Blog Post' : 'Create New Blog Post'}</h1>
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={() => setPreview(!preview)}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center"
            >
              {preview ? <FaTimes className="mr-2" /> : <FaEye className="mr-2" />}
              {preview ? 'Exit Preview' : 'Preview'}
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 flex items-center"
              disabled={isLoading}
            >
              <FaSave className="mr-2" />
              {isLoading ? 'Saving...' : 'Save Post'}
            </button>
          </div>
        </div>

        {!preview ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={post.title}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                  required
                />
              </div>
              <div>
                <label htmlFor="author_name" className="block text-sm font-medium text-gray-700 mb-1">Author Name</label>
                <input
                  type="text"
                  id="author_name"
                  name="author_name"
                  value={post.author_name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                />
              </div>
            </div>

            <div>
              <label htmlFor="excerpt" className="block text-sm font-medium text-gray-700 mb-1">Excerpt</label>
              <textarea
                id="excerpt"
                name="excerpt"
                value={post.excerpt}
                onChange={handleInputChange}
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                required
              />
            </div>

            <div>
              <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">Main Image</label>
              <div className="mt-1 flex items-center">
                <span className="inline-block h-32 w-32 rounded-lg overflow-hidden bg-gray-100">
                  {post.imagePreview ? (
                    <img src={post.imagePreview} alt="Preview" className="h-full w-full object-cover" />
                  ) : post.image_filename ? (
                    <img src={getBlogImageUrl(post.image_filename)} alt="Main blog image" className="h-full w-full object-cover" />
                  ) : (
                    <div className="h-full w-full flex items-center justify-center bg-gray-200">
                      <FaImage className="h-12 w-12 text-gray-400" />
                    </div>
                  )}
                </span>
                <label htmlFor="image-upload" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer">
                  <span>Change Image</span>
                  <input id="image-upload" name="image" type="file" className="sr-only" onChange={handleImageChange} accept="image/*" />
                </label>
              </div>
            </div>

            <div>
              <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">Content</label>
              <CKEditor
                editor={ClassicEditor}
                data={post.content}
                onChange={handleEditorChange}
                config={{
                  toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable', 'mediaEmbed', '|', 'undo', 'redo'],
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
                    ]
                  }
                }}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-1">Tags (comma-separated)</label>
                <input
                  type="text"
                  id="tags"
                  name="tags"
                  value={post.tags.join(', ')}
                  onChange={handleTagChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                />
              </div>
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700 mb-1">Status</label>
                <select
                  id="status"
                  name="status"
                  value={post.status}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                >
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                  <option value="archived">Archived</option>
                </select>
              </div>
            </div>

            <div className="border-t border-gray-200 pt-8">
              <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">SEO Settings</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="seo_title" className="block text-sm font-medium text-gray-700 mb-1">SEO Title</label>
                  <input
                    type="text"
                    id="seo_title"
                    name="seo_title"
                    value={post.seo_title}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                  />
                </div>
                <div>
                  <label htmlFor="seo_keywords" className="block text-sm font-medium text-gray-700 mb-1">SEO Keywords</label>
                  <input
                    type="text"
                    id="seo_keywords"
                    name="seo_keywords"
                    value={post.seo_keywords}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="seo_description" className="block text-sm font-medium text-gray-700 mb-1">SEO Description</label>
                <textarea
                  id="seo_description"
                  name="seo_description"
                  value={post.seo_description}
                  onChange={handleInputChange}
                  rows="3"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="prose prose-lg max-w-none">
            <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
            <p className="text-gray-600 mb-4">By {post.author_name}</p>
            {(post.imagePreview || post.image_filename) && (
              <img 
                src={post.imagePreview || getBlogImageUrl(post.image_filename)} 
                alt={post.title} 
                className="w-full h-64 object-cover rounded-lg shadow-md mb-6"
              />
            )}
            <p className="text-xl font-semibold mb-6">{post.excerpt}</p>
            {renderContent()}
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-2">Tags</h2>
              <div className="flex flex-wrap">
                {post.tags.map((tag, index) => (
                  <span key={index} className="bg-gray-200 text-gray-700 px-3 py-1 rounded-full mr-2 mb-2">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-2">SEO Preview</h2>
              <div className="bg-gray-100 p-4 rounded-lg">
                <h3 className="text-xl font-semibold text-blue-600">{post.seo_title || post.title}</h3>
                <p className="text-green-700 text-sm">{window.location.origin}/blog/{id || 'post-slug'}</p>
                <p className="text-sm mt-1">
                  {(post.seo_description || post.excerpt || DOMPurify.sanitize(post.content, {ALLOWED_TAGS: []}))
                    .slice(0, 160)}
                  {(post.seo_description || post.excerpt || post.content).length > 160 ? '...' : ''}
                </p>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default BlogEditor;