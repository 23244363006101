import React, { useState, useEffect } from 'react';
import { Search, Star, X, ChevronDown, Filter } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const FilterSection = ({ title, children, isOpen, toggleOpen }) => {
  return (
    <div className="mb-4">
      <button
        className="w-full flex justify-between items-center py-2 text-gray-700 hover:text-teal-600 transition-colors duration-200"
        onClick={toggleOpen}
      >
        <span className="font-medium">{title}</span>
        <motion.div
          initial={false}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown size={18} />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="py-2">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const CategoryItem = ({ category, selectedCategories, selectedSubcategories, handleCategoryChange, handleSubcategoryChange }) => {
  const [isSubcategoriesOpen, setIsSubcategoriesOpen] = useState(false);

  return (
    <div className="mb-2">
      <div className="flex items-center">
        <input
          type="checkbox"
          id={`category-${category.id}`}
          checked={selectedCategories.includes(category.id)}
          onChange={() => handleCategoryChange(category.id)}
          className="mr-2 accent-teal-500 h-4 w-4"
        />
        <label 
          htmlFor={`category-${category.id}`} 
          className="text-gray-700 hover:text-teal-600 cursor-pointer transition-colors duration-200 flex-grow"
        >
          {category.name}
        </label>
        {category.subcategories && category.subcategories.length > 0 && (
          <button
            onClick={() => setIsSubcategoriesOpen(!isSubcategoriesOpen)}
            className="text-teal-500 hover:text-teal-700 transition-colors duration-200 p-1 rounded-full hover:bg-teal-100"
          >
            <motion.div
              initial={false}
              animate={{ rotate: isSubcategoriesOpen ? 180 : 0 }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <ChevronDown size={16} />
            </motion.div>
          </button>
        )}
      </div>
      {category.subcategories && category.subcategories.length > 0 && (
        <AnimatePresence initial={false}>
          {isSubcategoriesOpen && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
              className="ml-6 mt-2"
            >
              {category.subcategories.map(subcategory => (
                <div key={subcategory.id} className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    id={`subcategory-${subcategory.id}`}
                    checked={selectedSubcategories.includes(subcategory.id)}
                    onChange={() => handleSubcategoryChange(subcategory.id)}
                    className="mr-2 accent-teal-500 h-4 w-4"
                  />
                  <label htmlFor={`subcategory-${subcategory.id}`} className="text-gray-600 hover:text-teal-500 cursor-pointer transition-colors duration-200">{subcategory.name}</label>
                </div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

const FilterSidebar = ({
  brands,
  categories,
  selectedBrands,
  selectedCategories,
  selectedSubcategories,
  priceRange,
  selectedRatings,
  onFilterChange,
  onClearAllFilters,
  isMobileOpen,
  toggleMobileFilters
}) => {
  const [searchBrand, setSearchBrand] = useState('');
  const [openSections, setOpenSections] = useState({
    brands: true,
    categories: true,
    priceRange: true,
    ratings: true
  });

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleBrandChange = (brandId) => {
    const updatedBrands = selectedBrands.includes(brandId)
      ? selectedBrands.filter((id) => id !== brandId)
      : [...selectedBrands, brandId];
    onFilterChange('brands', updatedBrands);
  };

  const handleCategoryChange = (categoryId) => {
    const updatedCategories = selectedCategories.includes(categoryId)
      ? selectedCategories.filter((id) => id !== categoryId)
      : [...selectedCategories, categoryId];
    onFilterChange('categories', updatedCategories);
  };

  const handleSubcategoryChange = (subcategoryId) => {
    const updatedSubcategories = selectedSubcategories.includes(subcategoryId)
      ? selectedSubcategories.filter((id) => id !== subcategoryId)
      : [...selectedSubcategories, subcategoryId];
    onFilterChange('subcategories', updatedSubcategories);
  };

  const handleRatingChange = (rating) => {
    const updatedRatings = selectedRatings.includes(rating)
      ? selectedRatings.filter((r) => r !== rating)
      : [...selectedRatings, rating];
    onFilterChange('ratings', updatedRatings);
  };

  const filteredBrands = brands.filter((brand) =>
    brand.name.toLowerCase().includes(searchBrand.toLowerCase())
  );

  useEffect(() => {
    if (isMobileOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileOpen]);

  return (
    <>
      <div className={`
        fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300
        ${isMobileOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}
        lg:hidden
      `} onClick={toggleMobileFilters}></div>
      <div className={`
        fixed inset-y-0 left-0 w-80 bg-white shadow-lg z-40 transform transition-transform duration-300 ease-in-out
        overflow-y-auto lg:sticky lg:top-0 lg:translate-x-0 lg:w-64 lg:h-screen lg:shadow-none
        ${isMobileOpen ? 'translate-x-0' : '-translate-x-full'}
      `}>
        <div className="h-full bg-teal-50 p-4 lg:p-6">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold text-gray-800">Filters</h2>
            <div className="flex items-center">
              <button
                onClick={onClearAllFilters}
                className="text-sm text-teal-600 hover:text-teal-700 transition-colors duration-200 mr-4"
              >
                Clear All
              </button>
              <button
                onClick={toggleMobileFilters}
                className="lg:hidden text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
          </div>

          <FilterSection 
            title="Brands" 
            isOpen={openSections.brands}
            toggleOpen={() => toggleSection('brands')}
          >
            <div className="relative mb-3">
              <input
                type="text"
                placeholder="Search brands"
                className="w-full p-2 pl-8 border border-gray-300 rounded-md text-sm"
                value={searchBrand}
                onChange={(e) => setSearchBrand(e.target.value)}
              />
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
            </div>
            <div className="max-h-40 overflow-y-auto">
              {filteredBrands.map(brand => (
                <div key={brand.id} className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id={`brand-${brand.id}`}
                    checked={selectedBrands.includes(brand.id)}
                    onChange={() => handleBrandChange(brand.id)}
                    className="mr-2 accent-teal-500"
                  />
                  <label htmlFor={`brand-${brand.id}`} className="text-sm text-gray-700 hover:text-teal-600 cursor-pointer">{brand.name}</label>
                </div>
              ))}
            </div>
          </FilterSection>

          <FilterSection 
            title="Categories" 
            isOpen={openSections.categories}
            toggleOpen={() => toggleSection('categories')}
          >
            {categories.map(category => (
              <CategoryItem
                key={category.id}
                category={category}
                selectedCategories={selectedCategories}
                selectedSubcategories={selectedSubcategories}
                handleCategoryChange={handleCategoryChange}
                handleSubcategoryChange={handleSubcategoryChange}
              />
            ))}
          </FilterSection>

          <FilterSection 
            title="Price Range" 
            isOpen={openSections.priceRange}
            toggleOpen={() => toggleSection('priceRange')}
          >
            <input
              type="range"
              min="0"
              max="150"
              value={priceRange[1]}
              onChange={(e) => onFilterChange('priceRange', [priceRange[0], parseInt(e.target.value)])}
              className="w-full accent-teal-500"
            />
            <div className="flex justify-between mt-2 text-teal-700">
              <span>£{priceRange[0]}</span>
              <span>£{priceRange[1]}</span>
            </div>
          </FilterSection>

          <FilterSection 
            title="Ratings" 
            isOpen={openSections.ratings}
            toggleOpen={() => toggleSection('ratings')}
          >
            {[5, 4, 3, 2, 1].map(rating => (
              <div key={rating} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id={`rating-${rating}`}
                  checked={selectedRatings.includes(rating)}
                  onChange={() => handleRatingChange(rating)}
                  className="mr-2 accent-teal-500 h-4 w-4"
                />
                <label htmlFor={`rating-${rating}`} className="flex items-center text-gray-700 hover:text-teal-600 cursor-pointer transition-colors duration-200">
                  {[...Array(rating)].map((_, i) => (
                    <Star key={i} size={16} className="text-yellow-400 fill-current" />
                  ))}
                  <span className="ml-2">& Up</span>
                </label>
              </div>
            ))}
          </FilterSection>
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;