import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DOMPurify from 'dompurify';
import { getBlogPostById, getBlogImageUrl } from '../services/api';
import './BlogPost.css';

const TableOfContents = ({ headings }) => (
  <nav className="table-of-contents">
    <h2>Table of Contents</h2>
    <ul>
      {headings.map((heading, index) => (
        <li key={index} className={`toc-level-${heading.level}`}>
          <a href={`#${heading.id}`}>{heading.text}</a>
        </li>
      ))}
    </ul>
  </nav>
);

const BlogPostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [headings, setHeadings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await getBlogPostById(id);
        const postData = response.data;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = postData.content;
        const extractedHeadings = Array.from(tempDiv.querySelectorAll('h2, h3')).map((heading, index) => {
          const id = `heading-${index}`;
          heading.id = id;
          return {
            text: heading.textContent,
            id: id,
            level: parseInt(heading.tagName.charAt(1))
          };
        });

        postData.content = tempDiv.innerHTML;
        setPost(postData);
        setHeadings(extractedHeadings);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError('Failed to load blog post. Please try again later.');
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const renderContent = () => {
    const sanitizedContent = DOMPurify.sanitize(post.content, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
    });

    const formattedContent = sanitizedContent.replace(
      /<h3/g,
      '<h3 class="indented-heading"'
    );

    return (
      <div 
        className="blog-content"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      />
    );
  };

  const cleanTag = (tag) => {
    return tag.replace(/[\[\]"\\]/g, '').trim();
  };

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!post) return <div className="not-found-message">Blog post not found</div>;

  const cleanedTags = post.tags
    ? (typeof post.tags === 'string' ? JSON.parse(post.tags) : post.tags)
        .map(cleanTag)
        .filter(tag => tag.length > 0)
    : [];

  return (
    <div className="blog-post-container">
      <Link to="/blog" className="back-link">
        <ArrowLeft size={20} />
        <span>Back to Blog</span>
      </Link>
      <article className="blog-post">
        {post.image_filename && (
          <img 
            src={getBlogImageUrl(post.image_filename)} 
            alt={post.title} 
            className="featured-image"
          />
        )}
        <h1 className="blog-title">{post.title}</h1>
        <div className="blog-meta">
          <p>By {post.author_name || 'Unknown Author'}</p>
          <p>{new Date(post.published_at).toLocaleDateString()}</p>
        </div>
        
        <p className="blog-excerpt">{post.excerpt}</p>
        
        <TableOfContents headings={headings} />
        
        {renderContent()}

        {cleanedTags.length > 0 && (
          <div className="blog-tags">
            <h2>Tags</h2>
            <div className="tags-container">
              {cleanedTags.map((tag, index) => (
                <span key={index} className="tag">{tag}</span>
              ))}
            </div>
          </div>
        )}
      </article>
    </div>
  );
};

export default BlogPostPage;