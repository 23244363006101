import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Dropdown = ({ category, onCategoryClick, onSubcategoryClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div 
      className="relative" 
      ref={dropdownRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className="flex items-center hover:text-teal-500 focus:outline-none transition-colors duration-300 group"
        onClick={() => {
          onCategoryClick(category.id);
          setIsOpen(!isOpen);
        }}
      >
        {category.name}
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="ml-1"
        >
          <ChevronDown size={14} className="text-teal-500 group-hover:text-teal-600" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && category.subcategories && category.subcategories.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute left-0 mt-2 w-56 bg-white border border-teal-100 rounded-lg shadow-lg z-50 overflow-hidden"
          >
            {category.subcategories.map((subcategory) => (
              <Link
                key={subcategory.id}
                to={`/shop?subcategories=${subcategory.id}`}
                className="block w-full text-left px-4 py-3 hover:bg-teal-50 transition-colors duration-200 flex items-center justify-between group"
                onClick={(e) => {
                  e.preventDefault();
                  onSubcategoryClick(subcategory.id);
                  setIsOpen(false);
                }}
              >
                <span className="text-gray-700 group-hover:text-teal-600 transition-colors duration-200">
                  {subcategory.name}
                </span>
                <ChevronRight size={14} className="text-teal-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;