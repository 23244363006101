import React, { useState, useEffect } from 'react';
import { getUserAddresses, createAddress, updateAddress, deleteAddress, setDefaultAddress } from '../services/api';
import { motion } from 'framer-motion';
import { MapPin, Plus, Edit2, Trash2, Star } from 'lucide-react';

const ManageAddresses = () => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({ full_name: '', address_line1: '', address_line2: '', city: '', state: '', postal_code: '', country: '', phone: '' });

  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      setIsLoading(true);
      const response = await getUserAddresses();
      setAddresses(response);
    } catch (err) {
      setError('Failed to load addresses. Please try again later.');
      console.error('Error fetching addresses:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await createAddress(newAddress);
      setAddresses([...addresses, response]);
      setIsAddingAddress(false);
      setNewAddress({ full_name: '', address_line1: '', address_line2: '', city: '', state: '', postal_code: '', country: '', phone: '' });
    } catch (err) {
      setError('Failed to add address. Please try again.');
      console.error('Error adding address:', err);
    }
  };

  const handleUpdateAddress = async (id, updatedAddress) => {
    try {
      const response = await updateAddress(id, updatedAddress);
      setAddresses(addresses.map(addr => addr.id === id ? response : addr));
    } catch (err) {
      setError('Failed to update address. Please try again.');
      console.error('Error updating address:', err);
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      await deleteAddress(id);
      setAddresses(addresses.filter(addr => addr.id !== id));
    } catch (err) {
      setError('Failed to delete address. Please try again.');
      console.error('Error deleting address:', err);
    }
  };

  const handleSetDefaultAddress = async (id) => {
    try {
      const response = await setDefaultAddress(id);
      setAddresses(addresses.map(addr => ({
        ...addr,
        is_default: addr.id === id
      })));
    } catch (err) {
      setError('Failed to set default address. Please try again.');
      console.error('Error setting default address:', err);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-teal-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 mt-8">
        <p>{error}</p>
        <button 
          onClick={fetchAddresses} 
          className="mt-4 bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <h1 className="text-3xl font-bold text-teal-700 mb-8">Manage Addresses</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {addresses.map((address) => (
          <motion.div 
            key={address.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white shadow-lg rounded-lg overflow-hidden"
          >
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <MapPin className="text-teal-500" size={24} />
                <div className="flex space-x-2">
                  <button onClick={() => handleSetDefaultAddress(address.id)}>
                    <Star className={`${address.is_default ? 'text-yellow-500' : 'text-gray-300'} hover:text-yellow-500`} size={18} />
                  </button>
                  <button onClick={() => handleUpdateAddress(address.id, { ...address })}>
                    <Edit2 className="text-teal-500 hover:text-teal-600" size={18} />
                  </button>
                  <button onClick={() => handleDeleteAddress(address.id)}>
                    <Trash2 className="text-red-500 hover:text-red-600" size={18} />
                  </button>
                </div>
              </div>
              <p className="text-gray-700 font-semibold">{address.full_name}</p>
              <p className="text-gray-700">{address.address_line1}</p>
              {address.address_line2 && <p className="text-gray-700">{address.address_line2}</p>}
              <p className="text-gray-700">{address.city}, {address.state} {address.postal_code}</p>
              <p className="text-gray-700">{address.country}</p>
              <p className="text-gray-700">{address.phone}</p>
              {address.is_default && <p className="text-teal-500 mt-2">Default Address</p>}
            </div>
          </motion.div>
        ))}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="bg-white shadow-lg rounded-lg overflow-hidden"
        >
          <div className="p-6">
            {isAddingAddress ? (
              <form onSubmit={handleAddAddress} className="space-y-4">
                <select
                  value={newAddress.address_type}
                  onChange={(e) => setNewAddress({...newAddress, address_type: e.target.value})}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                >
                  <option value="">Select Address Type</option>
                  <option value="shipping">Shipping</option>
                  <option value="billing">Billing</option>
                </select>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={newAddress.full_name}
                  onChange={(e) => setNewAddress({ ...newAddress, full_name: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="text"
                  placeholder="Address Line 1"
                  value={newAddress.address_line1}
                  onChange={(e) => setNewAddress({ ...newAddress, address_line1: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="text"
                  placeholder="Address Line 2 (Optional)"
                  value={newAddress.address_line2}
                  onChange={(e) => setNewAddress({ ...newAddress, address_line2: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <input
                  type="text"
                  placeholder="City"
                  value={newAddress.city}
                  onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="text"
                  placeholder="State"
                  value={newAddress.state}
                  onChange={(e) => setNewAddress({ ...newAddress, state: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="text"
                  placeholder="Postal Code"
                  value={newAddress.postal_code}
                  onChange={(e) => setNewAddress({ ...newAddress, postal_code: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={newAddress.country}
                  onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  value={newAddress.phone}
                  onChange={(e) => setNewAddress({ ...newAddress, phone: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                />
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setIsAddingAddress(false)}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors duration-300"
                  >
                    Add Address
                  </button>
                </div>
              </form>
            ) : (
              <button
                onClick={() => setIsAddingAddress(true)}
                className="flex items-center justify-center w-full h-full text-teal-500 hover:text-teal-600 transition-colors duration-300"
              >
                <Plus size={24} />
                <span className="ml-2">Add New Address</span>
              </button>
            )}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ManageAddresses;