import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCart, addToCart as apiAddToCart, removeFromCart as apiRemoveFromCart, updateCartItem as apiUpdateCartItem } from '../services/api';
import { useAuth } from './AuthContext';
import { toast } from 'react-toastify';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      fetchCart();
    } else {
      const savedCart = localStorage.getItem('cart');
      if (savedCart) {
        setCart(JSON.parse(savedCart));
      }
      setLoading(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }, [cart, isAuthenticated]);

  const fetchCart = async () => {
    try {
      setLoading(true);
      const response = await getCart();
      setCart(response.data.items);
    } catch (error) {
      console.error('Error fetching cart:', error);
      toast.error('Failed to fetch cart. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const addToCart = async (product, quantity = 1) => {
    console.log('Adding to cart:', product, 'Quantity:', quantity);
    console.log('Is authenticated:', isAuthenticated);
    
    try {
      if (isAuthenticated) {
        console.log('Sending API request to add to cart');
        const response = await apiAddToCart(product.id, quantity);
        console.log('API response:', response);
        await fetchCart();
      } else {
        console.log('Adding to local cart');
        setCart(currentCart => {
          const existingItem = currentCart.find(item => item.id === product.id);
          if (existingItem) {
            return currentCart.map(item =>
              item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item
            );
          }
          return [...currentCart, { ...product, quantity }];
        });
      }
      toast.success('Product added to cart!');
    } catch (error) {
      console.error('Error adding to cart:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      }
      toast.error(`Failed to add product to cart: ${error.message}`);
    }
  };

  const removeFromCart = async (productId) => {
    if (isAuthenticated) {
      try {
        await apiRemoveFromCart(productId);
        await fetchCart();
        toast.success('Product removed from cart!');
      } catch (error) {
        console.error('Error removing from cart:', error);
        toast.error('Failed to remove product from cart. Please try again.');
      }
    } else {
      setCart(currentCart => currentCart.filter(item => item.id !== productId));
      toast.success('Product removed from cart!');
    }
  };

  const updateQuantity = async (productId, quantity) => {
    if (isAuthenticated) {
      try {
        await apiUpdateCartItem(productId, quantity);
        await fetchCart();
        toast.success('Cart updated!');
      } catch (error) {
        console.error('Error updating cart item:', error);
        toast.error('Failed to update cart. Please try again.');
      }
    } else {
      setCart(currentCart =>
        currentCart.map(item =>
          item.id === productId ? { ...item, quantity: Math.max(0, quantity) } : item
        ).filter(item => item.quantity > 0)
      );
      toast.success('Cart updated!');
    }
  };

  const clearCart = () => {
    setCart([]);
    if (!isAuthenticated) {
      localStorage.removeItem('cart');
    }
    toast.info('Cart cleared!');
  };

  const getCartTotal = () => {
    return cart.reduce((total, item) => {
      const price = parseFloat(item.product_price);
      return total + (price * item.quantity);
    }, 0);
  };

  const getCartItemsCount = () => {
    return cart.reduce((count, item) => count + item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{
      cart,
      loading,
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart,
      getCartTotal,
      getCartItemsCount,
    }}>
      {children}
    </CartContext.Provider>
  );
};