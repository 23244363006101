import React from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import BitcoinPayment from '../components/BitcoinPayment';
import { Bitcoin, ArrowLeft, CheckCircle, InfoIcon } from 'lucide-react';

const BitcoinPaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, bitcoinDetails, totalAmount } = location.state || {};

  if (!orderId || !bitcoinDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
          <p className="text-gray-700 mb-4">Missing payment information. Please try again.</p>
          <button
            onClick={() => navigate('/shop')}
            className="bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-600 transition duration-300"
          >
            Return to Shop
          </button>
        </div>
      </div>
    );
  }

  const displayAmount = totalAmount || bitcoinDetails.amount_gbp;

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="bg-teal-600 px-6 py-4 flex items-center justify-between">
            <h1 className="text-2xl font-bold text-white flex items-center">
              <Bitcoin className="mr-2" /> Bitcoin Payment
            </h1>
            <span className="text-white text-sm bg-teal-700 px-3 py-1 rounded-full">
              Order ID: {orderId}
            </span>
          </div>
          <div className="p-6">
            <BitcoinPayment paymentDetails={bitcoinDetails} />
            <p className="mt-6 text-center text-lg font-semibold text-gray-700">
              Total Amount: £{displayAmount}
            </p>
            <Link 
              to="/bitcoin-payment-demo" 
              className="mt-4 flex items-center justify-center text-teal-600 hover:text-teal-800 transition duration-300"
            >
              <InfoIcon className="mr-2" size={20} />
              Learn more about Bitcoin payments
            </Link>
          </div>
          <div className="bg-gray-50 px-6 py-4 flex justify-between items-center">
            <button
              onClick={() => navigate('/shop')}
              className="flex items-center text-gray-600 hover:text-gray-800 transition duration-300"
            >
              <ArrowLeft className="mr-2" size={20} />
              Return to Shop
            </button>
            <button
              onClick={() => navigate('/order-confirmation', { state: { orderId } })}
              className="bg-teal-500 text-white py-2 px-4 rounded-lg hover:bg-teal-600 transition duration-300 flex items-center"
            >
              <CheckCircle className="mr-2" size={20} />
              Confirm Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BitcoinPaymentPage;