import React, { useState, useEffect } from 'react';
import { getCoupons, createCoupon, updateCoupon, deleteCoupon } from '../../services/api';
import { Plus, Edit, Trash2, ChevronLeft, ChevronRight, X } from 'lucide-react';

const CouponManagement = () => {
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingCoupon, setEditingCoupon] = useState(null);

  useEffect(() => {
    fetchCoupons();
  }, [currentPage]);

  const fetchCoupons = async () => {
    try {
      setIsLoading(true);
      const response = await getCoupons({ page: currentPage, limit: itemsPerPage });
      if (response.data && Array.isArray(response.data.items)) {
        setCoupons(response.data.items);
        setTotalPages(Math.ceil(response.data.total / itemsPerPage));
      } else {
        console.error('Unexpected API response format:', response.data);
        setError('Unexpected data format received from the server.');
        setCoupons([]);
      }
    } catch (err) {
      setError('Failed to load coupons. Please try again.');
      console.error('Error fetching coupons:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCoupon = async (couponData) => {
    try {
      const response = await createCoupon(couponData);
      setCoupons(prevCoupons => [...prevCoupons, response.data]);
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to create coupon. Please try again.');
      console.error('Error creating coupon:', err);
    }
  };

  const handleUpdateCoupon = async (id, couponData) => {
    try {
      const response = await updateCoupon(id, couponData);
      setCoupons(prevCoupons => 
        prevCoupons.map(coupon => coupon.id === id ? response.data : coupon)
      );
      setIsModalOpen(false);
      setEditingCoupon(null);
    } catch (err) {
      setError('Failed to update coupon. Please try again.');
      console.error('Error updating coupon:', err);
    }
  };

  const handleDeleteCoupon = async (id) => {
    if (window.confirm('Are you sure you want to delete this coupon?')) {
      try {
        await deleteCoupon(id);
        setCoupons(prevCoupons => prevCoupons.filter(coupon => coupon.id !== id));
      } catch (err) {
        setError('Failed to delete coupon. Please try again.');
        console.error('Error deleting coupon:', err);
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) return <div className="text-center py-10">Loading coupons...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Coupon Management</h1>
      <button
        onClick={() => {
          setEditingCoupon(null);
          setIsModalOpen(true);
        }}
        className="mb-8 bg-teal-500 text-white px-6 py-2 rounded-full hover:bg-teal-600 transition-colors duration-300 flex items-center shadow-lg"
      >
        <Plus size={20} className="mr-2" />
        Add New Coupon
      </button>
      
      {coupons.length === 0 ? (
        <p className="text-center py-10 text-gray-500">No coupons found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="py-3 px-4 text-left">Code</th>
                <th className="py-3 px-4 text-left">Discount</th>
                <th className="py-3 px-4 text-left">Valid From</th>
                <th className="py-3 px-4 text-left">Valid Until</th>
                <th className="py-3 px-4 text-left">Usage Limit</th>
                <th className="py-3 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {coupons.map(coupon => (
                <tr key={coupon.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-4">{coupon.code}</td>
                  <td className="py-3 px-4">{coupon.discount_type === 'percentage' ? `${coupon.discount_value}%` : `£${coupon.discount_value}`}</td>
                  <td className="py-3 px-4">{new Date(coupon.valid_from).toLocaleDateString()}</td>
                  <td className="py-3 px-4">{new Date(coupon.valid_until).toLocaleDateString()}</td>
                  <td className="py-3 px-4">{coupon.usage_limit}</td>
                  <td className="py-3 px-4">
                    <button
                      onClick={() => {
                        setEditingCoupon(coupon);
                        setIsModalOpen(true);
                      }}
                      className="text-blue-500 hover:text-blue-700 mr-2"
                    >
                      <Edit size={18} />
                    </button>
                    <button
                      onClick={() => handleDeleteCoupon(coupon.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <Trash2 size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-8 flex justify-center items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 rounded-full bg-gray-200 disabled:opacity-50 hover:bg-gray-300 transition-colors duration-300"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-medium">{currentPage} of {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 rounded-full bg-gray-200 disabled:opacity-50 hover:bg-gray-300 transition-colors duration-300"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      )}

      {/* Modal for creating/editing coupons */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-w-md w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">{editingCoupon ? 'Edit Coupon' : 'Add New Coupon'}</h2>
              <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <CouponForm
              coupon={editingCoupon}
              onSave={(couponData) => {
                if (editingCoupon) {
                  handleUpdateCoupon(editingCoupon.id, couponData);
                } else {
                  handleCreateCoupon(couponData);
                }
              }}
              onCancel={() => setIsModalOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CouponForm = ({ coupon, onSave, onCancel }) => {
  const [formData, setFormData] = useState(coupon || {
    code: '',
    discount_type: 'percentage',
    discount_value: '',
    valid_from: '',
    valid_until: '',
    usage_limit: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">Coupon Code</label>
        <input
          type="text"
          id="code"
          name="code"
          value={formData.code}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>
      <div>
        <label htmlFor="discount_type" className="block text-sm font-medium text-gray-700 mb-1">Discount Type</label>
        <select
          id="discount_type"
          name="discount_type"
          value={formData.discount_type}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        >
          <option value="percentage">Percentage</option>
          <option value="fixed">Fixed Amount</option>
        </select>
      </div>
      <div>
        <label htmlFor="discount_value" className="block text-sm font-medium text-gray-700 mb-1">Discount Value</label>
        <input
          type="number"
          id="discount_value"
          name="discount_value"
          value={formData.discount_value}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>
      <div>
        <label htmlFor="valid_from" className="block text-sm font-medium text-gray-700 mb-1">Valid From</label>
        <input
          type="date"
          id="valid_from"
          name="valid_from"
          value={formData.valid_from}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>
      <div>
        <label htmlFor="valid_until" className="block text-sm font-medium text-gray-700 mb-1">Valid Until</label>
        <input
          type="date"
          id="valid_until"
          name="valid_until"
          value={formData.valid_until}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>
      <div>
        <label htmlFor="usage_limit" className="block text-sm font-medium text-gray-700 mb-1">Usage Limit</label>
        <input
          type="number"
          id="usage_limit"
          name="usage_limit"
          value={formData.usage_limit}
          onChange={handleChange}
          className="w-full p-2 border rounded focus:ring-teal-500 focus:border-teal-500"
          required
        />
      </div>
      <div className="flex justify-end space-x-4 mt-6">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-full hover:bg-gray-400 transition-colors duration-300"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-teal-500 text-white rounded-full hover:bg-teal-600 transition-colors duration-300"
        >
          {coupon ? 'Update Coupon' : 'Create Coupon'}
        </button>
      </div>
    </form>
  );
};

export default CouponManagement;