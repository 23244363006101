import React from 'react';
import QRCode from 'qrcode.react';
import { Copy } from 'lucide-react';

const BitcoinPayment = ({ paymentDetails }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4 text-gray-800">Payment Details</h3>
      <p className="mb-2 text-gray-700">Please send <span className="font-bold">{paymentDetails.amount_btc} BTC</span> to the following address:</p>
      <div className="flex items-center mb-4">
        <p className="font-mono bg-gray-100 p-2 rounded flex-grow mr-2 break-all">{paymentDetails.bitcoin_address}</p>
        <button 
          onClick={() => copyToClipboard(paymentDetails.bitcoin_address)}
          className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition duration-300"
          title="Copy address"
        >
          <Copy size={20} />
        </button>
      </div>
      <div className="flex justify-center mb-6">
        <QRCode value={paymentDetails.bitcoin_address} size={200} />
      </div>
      <p className="mb-4 text-center text-lg font-semibold text-gray-700">
        Amount in GBP: £{paymentDetails.amount_gbp}
      </p>
      <p className="text-sm text-gray-600 bg-yellow-100 p-3 rounded-lg">
        The order will be processed once the payment is confirmed on the Bitcoin network. This usually takes about 10-30 minutes.
      </p>
    </div>
  );
};

export default BitcoinPayment;