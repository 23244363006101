import axios from 'axios';
export const STATIC_BASE_URL = process.env.REACT_APP_STATIC_BASE_URL || 'https://uksteroidsstore.com/api';

// Create an axios instance with a base URL and default headers
const api = axios.create({
  baseURL: 'https://uksteroidsstore.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the auth token in all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    console.log('Token being sent:', token); // Add this line
    if (token && token !== 'null' && token !== 'undefined') {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      // Instead of redirecting, we'll just reject the promise
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
// API functions for different endpoints
export const getStaticImageUrl = (imageName) => {
  if (!imageName) return null;
  const timestamp = new Date().getTime(); // or use a version number
  return `${STATIC_BASE_URL}/static/images/${imageName}?v=${timestamp}`;
};
export const getBlogImageUrl = (filename) => {
  if (!filename) return null;
  return `${STATIC_BASE_URL}/static/blog_images/${filename}`;
};
export const uploadBlogImage = async (imageFile) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return await api.post('/admin/blog/upload-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Products
export const getProducts = async ({
  page = 1,
  limit = 10,
  sort = 'popularity',
  min_price = 0,
  max_price = 1000,
  categories = [],
  subcategories = [],
  brands = [],
  ratings = []
} = {}) => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    sort: sort,
    min_price: min_price.toString(),
    max_price: max_price.toString(),
  });

  if (categories.length) {
    categories.forEach(cat => params.append('categories[]', cat.toString()));
  }
  if (subcategories.length) {
    subcategories.forEach(sub => params.append('subcategories[]', sub.toString()));
  }
  if (brands.length) {
    brands.forEach(brand => params.append('brands[]', brand.toString()));
  }
  if (ratings.length) {
    ratings.forEach(rating => params.append('ratings[]', rating.toString()));
  }

  return await api.get(`/products/?${params.toString()}`);
};
export const getProduct = (id) => api.get(`/products/${id}`);
export const searchProducts = async (query, page = 1, limit = 12) => {
  try {
    const response = await api.get('/products/search', {
      params: { q: query, page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching products:', error);
    handleApiError(error);
    throw error;
  }
};

export const getSearchSuggestions = async (query, limit = 5) => {
  try {
    const response = await api.get('/products/search/suggestions', {
      params: { q: query, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting search suggestions:', error);
    handleApiError(error);
    throw error;
  }
};

export const getRelatedProducts = async (productId) => {
  try {
    const response = await api.get(`/products/${productId}/related`);
    return response.data;
  } catch (error) {
    console.error('Error fetching related products:', error);
    handleApiError(error);
    throw error;
  }
};

// Categories
export const getCategories = () => api.get('/categories/');
export const getCategory = (id) => api.get(`/categories/${id}`);
export const createCategory = (data) => api.post('/categories', data);
export const updateCategory = (id, data) => api.put(`/categories/${id}`, data);
export const deleteCategory = (id) => api.delete(`/categories/${id}`);

// Subcategories
export const getSubcategories = (categoryId) => api.get(`/categories/${categoryId}/subcategories`);
export const getSubcategory = (id) => api.get(`/subcategories/${id}`);
export const createSubcategory = (data) => api.post('/subcategories', data);
export const updateSubcategory = (id, data) => api.put(`/subcategories/${id}`, data);
export const deleteSubcategory = (id) => api.delete(`/subcategories/${id}`);

// Brands
export const getBrands = () => api.get('/brands/');
export const getBrand = (id) => api.get(`/brands/${id}`);
export const createBrand = (data) => api.post('/brands', data);
export const updateBrand = (id, data) => api.put(`/brands/${id}`, data);
export const deleteBrand = (id) => api.delete(`/brands/${id}`);

// Users
export const login = async (credentials) => {
  const response = await api.post('/users/login', credentials);
  if (response.data.access_token) {
    localStorage.setItem('token', response.data.access_token);
  }
  return response;
};
export const register = (userData) => api.post('/users', userData);
export const getUserProfile = () => api.get('/users/me');
export const logout = () => api.post('/users/logout');
export const updateUserProfile = (data) => api.put('/users/me', data);
export const forgotPassword = (email) => api.post('/users/forgot-password', { email });
export const resetPassword = (token, new_password) => api.post('/users/reset-password', { token, new_password });
export const getAllUsers = (skip = 0, limit = 100) => api.get('/users/', { params: { skip, limit } });
export const getUserById = (userId) => api.get(`/users/${userId}`);
export const updateUser = (userId, userData) => api.put(`/users/${userId}`, userData);
export const deleteUser = (userId) => api.delete(`/users/${userId}`);
export const updateUserPreferences = (data) => {
  return api.put('/users/me/preferences', data, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
};

export const getUserPreferences = async () => {
  try {
    const response = await api.get('/users/me/preferences');
    return response.data;
  } catch (error) {
    console.error('Error fetching user preferences:', error.response?.data);
    throw error;
  }
};

export const getOrderHistory = async (page = 1, limit = 10) => {
  try {
    const response = await api.get('/users/me/my-orders', {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order history:', error.response?.data);
    throw error;
  }
};
export const getOrderByIdUser = async (orderId) => {
  try {
    const response = await api.get(`/users/me/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error.response?.data);
    throw error;
  }
};
// Address management
export const getUserAddresses = async () => {
  try {
    const response = await api.get('/addresses/');
    return response.data;
  } catch (error) {
    console.error('Error fetching user addresses:', error);
    handleApiError(error);
    throw error;
  }
};

export const createAddress = async (addressData) => {
  try {
    const response = await api.post('/addresses/', addressData);
    return response.data;
  } catch (error) {
    console.error('Error creating address:', error);
    handleApiError(error);
    throw error;
  }
};

export const updateAddress = async (addressId, addressData) => {
  try {
    const response = await api.put(`/addresses/${addressId}`, addressData);
    return response.data;
  } catch (error) {
    console.error('Error updating address:', error);
    handleApiError(error);
    throw error;
  }
};

export const deleteAddress = async (addressId) => {
  try {
    const response = await api.delete(`/addresses/${addressId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting address:', error);
    handleApiError(error);
    throw error;
  }
};

export const setDefaultAddress = async (addressId) => {
  try {
    const response = await api.post(`/addresses/set-default/${addressId}`);
    return response.data;
  } catch (error) {
    console.error('Error setting default address:', error);
    handleApiError(error);
    throw error;
  }
};

// Orders
export const getOrders = () => api.get('/orders');
export const getOrderUser = (id) => api.get(`/orders/${id}`);
export const createOrder = (data) => api.post('/orders/', data);


// Cart
export const getCart = () => api.get('/cart/');
export const addToCart = (productId, quantity) => api.post('/cart', { product_id: productId, quantity });
export const updateCartItem = async (itemId, quantity) => {
  try {
    const response = await api.put(`/cart/${itemId}?quantity=${quantity}`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating cart item:', error);
    throw error;
  }
};
export const removeFromCart = (productId) => api.delete(`/cart/${productId}`);

// Wishlist
export const getWishlist = () => api.get('/wishlist/');
export const addToWishlist = (productId) => api.post('/wishlist', { product_id: productId });
export const removeFromWishlist = (productId) => api.delete(`/wishlist/${productId}`);
export const checkWishlistItem = (productId) => api.get(`/wishlist/check/${productId}`);

// Payments
export const createPayment = async (paymentData) => {
  try {
    const response = await api.post('/payments/', paymentData);
    return response.data;
  } catch (error) {
    console.error('Error creating payment:', error);
    handleApiError(error);
    throw error;
  }
};

export const checkPaymentStatus = async (paymentId) => {
  try {
    const response = await api.get(`/payments/check/${paymentId}`);
    return response.data;
  } catch (error) {
    console.error('Error checking payment status:', error);
    handleApiError(error);
    throw error;
  }
};

export const getPaymentsByOrder = async (orderId) => {
  try {
    const response = await api.get(`/payments/order/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching payments for order:', error);
    handleApiError(error);
    throw error;
  }
};

export const getBankTransferDetails = async () => {
  try {
    const response = await api.get('/payments/bank-transfer-details');
    return response.data;
  } catch (error) {
    console.error('Error getting bank transfer details:', error);
    handleApiError(error);
    throw error;
  }
};

// Reviews
export const getProductReviews = (productId) => api.get(`/products/${productId}/reviews`);
export const canReviewProduct = async (orderId, productId) => {
  try {
    const response = await api.get(`/reviews/can-review/${orderId}/${productId}`);
    console.log('Can review response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error checking if can review:', error);
    throw error;
  }
};

export const createReview = async (orderId, productId, reviewData) => {
  try {
    const response = await api.post(`/reviews/order/${orderId}/product/${productId}`, reviewData);
    return response.data;
  } catch (error) {
    console.error('Error creating review:', error);
    throw error;
  }
};
export const updateReview = async (reviewId, reviewData) => {
  try {
    const response = await api.put(`/reviews/${reviewId}`, reviewData);
    return response.data;
  } catch (error) {
    console.error('Error updating review:', error);
    throw error;
  }
};
export const deleteReview = (reviewId) => api.delete(`/reviews/${reviewId}`);

// Blog Posts (Public)
export const getBlogPosts = (skip = 0, limit = 12) => api.get('/blog/', { params: { skip, limit } });
export const getBlogPostById = (id) => api.get(`/blog/${id}`);

// Admin Blog Posts
export const getAdminBlogPosts = (skip = 0, limit = 100, status = null) => 
  api.get('/admin/blog/', { params: { skip, limit, status } });

export const createAdminBlogPost = (formData) => {
  return api.post('/admin/blog/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const updateAdminBlogPost = (id, formData) => {
  return api.put(`/admin/blog/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const getAdminBlogPostById = (id) => api.get(`/admin/blog/${id}`);
export const deleteAdminBlogPost = (id) => api.delete(`/admin/blog/${id}`);
export const getAdminBlogPostRevisions = (postId) => api.get(`/admin/blog/${postId}/revisions`);
// Admin Coupon Management
export const getCoupons = (params) => api.get('/admin/coupons', { params });
export const createCoupon = (data) => api.post('/admin/coupons', data);
export const getCouponById = (id) => api.get(`/admin/coupons/${id}`);
export const updateCoupon = (id, data) => api.put(`/admin/coupons/${id}`, data);
export const deleteCoupon = (id) => api.delete(`/admin/coupons/${id}`);
export const searchCoupons = (query) => api.get(`/admin/coupons/search`, { params: { query } });
export const applyCoupon = async (couponCode) => {
  try {
    const response = await api.post('/coupons/apply', { coupon_code: couponCode });
    return response.data;
  } catch (error) {
    console.error('Error applying coupon:', error);
    handleApiError(error);
    throw error;
  }
};
// Admin Order Management (update existing functions)
export const getOrdersAdmin = (params) => api.get('/admin/orders', { params });
export const getOrderById = (id) => api.get(`/admin/orders/${id}`);
export const updateOrder = (id, data) => api.put(`/admin/orders/${id}`, data);
export const deleteOrder = (id) => api.delete(`/admin/orders/${id}`);
// Admin Products
export const getAdminProducts = async (page = 1, limit = 10) => {
  try {
    const response = await api.get('/admin/products', {
      params: { skip: (page - 1) * limit, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin products:', error);
    handleApiError(error);
    throw error;
  }
};

export const searchAdminProducts = async (query, page = 1, limit = 10) => {
  try {
    const response = await api.get('/admin/products', {
      params: { skip: (page - 1) * limit, limit, search: query }
    });
    return response.data;
  } catch (error) {
    console.error('Error searching admin products:', error);
    handleApiError(error);
    throw error;
  }
};

export const createAdminProduct = async (formData) => {
  try {
    const response = await api.post('/admin/products', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error in createAdminProduct:', error);
    throw error;
  }
};
export const updateAdminProduct = async (id, formData) => {
  console.log('Updating product with ID:', id);
  console.log('FormData contents:');
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }
  
  try {
    const response = await api.put(`/admin/products/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Update response:', response);
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

export const deleteAdminProduct = async (id) => {
  try {
    const response = await api.delete(`/admin/products/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting product:', error);
    handleApiError(error);
    throw error;
  }
};
// FAQs
export const getFAQs = async (page = 1, limit = 100) => {
  try {
    const response = await api.get('/faqs/', {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching FAQs:', error);
    handleApiError(error);
    throw error;
  }
};
export const createFAQ = (data) => api.post('/faqs', data);
export const updateFAQ = (id, data) => api.put(`/faqs/${id}`, data);
export const deleteFAQ = (id) => api.delete(`/faqs/${id}`);

// Admin functions
export const getUsers = (params) => {
  return api.get('/admin/users', { params })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching users:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(`Server error: ${error.response.status}`);
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error('No response received from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('Error setting up the request');
      }
    });
};
export const updateUserRole = (userId, role) => api.put(`/admin/users/${userId}/role`, { role });


export const getSettings = () => api.get('/admin/settings');
export const updateSettings = (data) => api.put('/admin/settings', data);

export const handleApiError = (error) => {
  if (error.response) {
    console.error('Data:', error.response.data);
    console.error('Status:', error.response.status);
    console.error('Headers:', error.response.headers);
    
    if (error.response.status === 401) {
      console.error('Unauthorized: Please check your authentication');
    } else if (error.response.status === 403) {
      console.error('Forbidden: You do not have permission to access this resource');
    } else if (error.response.status === 404) {
      console.error('Not Found: The requested resource does not exist');
    } else if (error.response.status === 500) {
      console.error('Internal Server Error: Please try again later');
    }
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error:', error.message);
  }
  console.error('Config:', error.config);
};

export default api;