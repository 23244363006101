import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { User, LogOut, Settings, ChevronDown } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const UserDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)} 
        className="flex items-center text-white hover:text-teal-100 transition-colors duration-300 group focus:outline-none"
      >
        <User className="mr-2 group-hover:text-teal-100" size={20} />
        <span className="font-semibold">{user.username}</span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
          className="ml-1"
        >
          <ChevronDown size={14} className="text-teal-100 group-hover:text-teal-200" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-56 bg-white border border-teal-100 rounded-lg shadow-lg z-50 overflow-hidden"
          >
            <Link to="/profile" className="block px-4 py-3 text-teal-800 hover:bg-teal-50 transition-colors duration-200 flex items-center">
              <User size={18} className="mr-2 text-teal-500" />
              <span>Profile</span>
            </Link>
            <Link to="/settings" className="block px-4 py-3 text-teal-800 hover:bg-teal-50 transition-colors duration-200 flex items-center">
              <Settings size={18} className="mr-2 text-teal-500" />
              <span>Settings</span>
            </Link>
            <button 
              onClick={() => {
                logout();
                setIsOpen(false);
              }} 
              className="block w-full text-left px-4 py-3 text-red-600 hover:bg-red-50 transition-colors duration-200 flex items-center"
            >
              <LogOut size={18} className="mr-2" />
              <span>Logout</span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserDropdown;