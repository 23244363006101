import React from 'react';
import { Truck, Package, Clock, HelpCircle, Mail, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';

const DeliveryInfoCard = ({ icon: Icon, title, children }) => (
  <div className="bg-white rounded-lg shadow-md p-6 flex flex-col h-full">
    <div className="flex items-center mb-4">
      <Icon className="text-teal-500 mr-3" size={24} />
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
    </div>
    <div className="text-gray-600 flex-grow">{children}</div>
  </div>
);

const DeliveryInformationPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center">Delivery Information</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mb-12">
          <DeliveryInfoCard icon={Package} title="How My Order Will be Packaged?">
            <p>We take great care in packaging your order to ensure it arrives safely and discreetly. All items are securely wrapped and placed in plain, unmarked boxes or padded envelopes, depending on the size and nature of the products.</p>
          </DeliveryInfoCard>
          
          <DeliveryInfoCard icon={Truck} title="How long does it take you to deliver?">
            <p>Delivery times vary depending on your location and chosen shipping method. Generally, orders within the UK are delivered within 3-5 business days. International orders may take 7-14 business days. Express shipping options are available for faster delivery.</p>
          </DeliveryInfoCard>
          
          <DeliveryInfoCard icon={Clock} title="The expected shipment date has passed">
            <p>If the expected shipment date has passed and you haven't received your order, please check your order status in your account. If there's no update, contact our customer service team with your order number, and we'll investigate the delay and provide you with an update as soon as possible.</p>
          </DeliveryInfoCard>
          
          <DeliveryInfoCard icon={HelpCircle} title="My order has been lost or delayed">
            <p>In the rare event that your order is lost or significantly delayed, please contact our customer service team immediately. We'll work with our shipping partners to locate your package or arrange for a replacement to be sent if necessary. Rest assured, we'll do everything we can to resolve the issue promptly.</p>
          </DeliveryInfoCard>
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Find Out More</h2>
          <p className="text-gray-600 mb-6">
            If you have any questions regarding our delivery process, you may contact us. You may also consider reading our FAQs and Terms and Conditions page to learn more about our complete order shipment process.
          </p>
          <div className="flex flex-wrap gap-4">
            <Link to="/contact" className="inline-flex items-center px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 transition duration-300">
              <Mail className="mr-2" size={20} />
              Contact Us
            </Link>
            <Link to="/faq" className="inline-flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
              <HelpCircle className="mr-2" size={20} />
              FAQs
            </Link>
            <Link to="/terms" className="inline-flex items-center px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-300">
              <FileText className="mr-2" size={20} />
              Terms and Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryInformationPage;